/**
 * Server 와의 Connection 및 Autorizaion 관련 Config 파일.
 *
 **/
import qs from "qs";
import axios from "axios";
// import router from "@/router";
import store from "@/store";

import { refreshToken, logOut } from "@/api/auth";

axios.defaults.withCredentials = true;

/**
 * object to queries
 * example) {a:1, b:2} => 'a=1&b=2'
 **/
export function toQueries(obj) {
  return qs.stringify(obj, { arrayFormat: "repeat" });
}

const authConfig = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  timeout: 30000,
  headers: {
    Authorization: getToken(),
    "Content-Type": "application/json",
  },
});

const authLongConfig = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  timeout: 60000 * 10,
  headers: {
    Authorization: getToken(),
    "Content-Type": "application/json",
  },
});

const authFileConfig = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  timeout: 100000,
  headers: {
    Authorization: getToken(),
    //"Content-Type": "multipart/form-data",
  },
});

const authBlobConfig = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  timeout: 30000,
  headers: {
    Authorization: getToken(),
    "Content-Type": "application/json",
  },
  responseType: "blob",
});

const noAuthConfig = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  timeout: 10000,
  headers: {
    Authorization: "",
    "Content-Type": "application/json",
  },
});

const docuConfig = axios.create({
  baseURL: process.env.VUE_APP_DOCU_ENDPOINT,
  timeout: 30000,
  headers: {'X-Auth-Token': process.env.VUE_APP_DOCU_API_KEY}
})

const cognitoToken = axios.create({
  baseURL: process.env.VUE_APP_COGNITO_ENDPOINT,
  timeout: 10000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

refreshOnTokenExpiredError(authConfig);
refreshOnTokenExpiredError(authLongConfig);
refreshOnTokenExpiredError(authBlobConfig);
refreshOnTokenExpiredError(authFileConfig);

export function AuthConnection(defaultAlert = false) {
  store.commit("SET_LOADING", true);

  authConfig.defaults.headers["Authorization"] = getToken();
  authConfig.defaults.defaultAlert = defaultAlert;
  return authConfig;
}

export function AuthLongConnection(defaultAlert = false) {
  store.commit("SET_LOADING", true);

  //authConfig.defaults.headers["content-Encoding"] = "gzip";
  authLongConfig.defaults.headers["Authorization"] = getToken();
  authLongConfig.defaults.defaultAlert = defaultAlert;
  return authLongConfig;
}

export function AuthFileConnection() {
  store.commit("SET_LOADING", true);

  authConfig.defaults.headers["Authorization"] = getToken();
  return authConfig;
}

export function AuthFilesConnection() {
  store.commit("SET_LOADING", true);

  authFileConfig.defaults.headers["Authorization"] = getToken();
  return authFileConfig;
}
export function AuthBlobConnection() {
  store.commit("SET_LOADING", true);

  authBlobConfig.defaults.headers["Authorization"] = getToken();
  return authBlobConfig;
}

export function NoAuthConnection() {
  return noAuthConfig;
}

export function docuConnection(){
  return docuConfig;
}

export function CognitoTokenConnection() {
  return cognitoToken;
}
export function getToken() {
  // Token이 setup 되지 않을 시.
  if (!store) return null;
  var token = store.getters.getToken;

  // Token이 잘못 지정되었을 때
  if (!token) return null;
  return `Bearer ${token}`;
}

function refreshOnTokenExpiredError(instance) {
  instance.interceptors.response.use(
    (response) => {
      store.commit("SET_LOADING", false);

      if (instance.defaults.defaultAlert) {
        if (
          response.config.method == "post" ||
          response.config.method == "put"
        ) {
          store.commit("SET_SUCCESS", "처리되었습니다.");
        } else if (response.config.method == "delete") {
          store.commit("SET_SUCCESS", "삭제 되었습니다.");
        }
      }

      return response;
    },
    async (error) => {
      store.commit("SET_LOADING", false);

      const {
        config,
        response: { status },
      } = error;

      if (status === 401) {
        let token = store.getters.getRefreshToken;
        if (!token) {
          // If there is no refresh token then sign out.
          // console.log("There is no token, so signout.");
          logOut();
        } else {
          // Refresh token and call failed request again.
          // console.log("Token is expired, so refresh token.");
          const originalRequest = config;
          await refreshToken(token);
          instance.defaults.headers["Authorization"] = getToken();
          originalRequest.headers.Authorization = getToken();
          // 401로 요청 실패했던 요청 새로운 accessToken으로 재요청
          return axios(originalRequest);
        }
      }

      if (status === 400 || status === 500 || status === 404) {
        if (instance.defaults.defaultAlert) {
          if (config.method == "post" || config.method == "put") {
            store.commit("SET_WARN", "저장에 실패하였습니다.");
          } else if (config.method == "delete") {
            store.commit("SET_WARN", "삭제에 실패하였습니다.");
          }
        }
      }

      if (status === 403) {
        let warn = {
          POST: "데이터 생성 권한이 없습니다.",
          GET: "데이터 접근 권한이 없습니다.",
          PUT: "데이터 수정 권한이 없습니다.",
          DELETE: "데이터 삭제 권한이 없습니다.",
        }[config.method.toUpperCase()];
        store.commit("SET_WARN", warn);
      }
      return Promise.reject(error);
    }
  );
}

export function getIds(data) {
  let ids = [];
  data.forEach((d) => ids.push(d.id));
  return JSON.stringify(ids).replace("[", "").replace("]", "");
}
