var analysisRoutes = [
  {
    path: "/analysis/totals",
    name: "AnalysisTotal",
    component: () => import("@/views/Analysis/AnalysisView"),
  },
  {
    path: "/analysis/centers",
    name: "AnalysisCenter",
    component: () => import("@/views/Analysis/AnalysisCenterView"),
  },
  {
    path: "/analysis/sites",
    name: "AnalysisSite",
    component: () => import("@/views/Analysis/AnalysisSiteView"),
  },
  {
    path: "/analysis/settings",
    name: "AnalysisSetting",
    component: () => import("@/views/Analysis/AnalysisSettingView"),
  },
  {
    path: "/analysis/scores",
    name: "AnalysisScore",
    component: () => import("@/views/Analysis/AnalysisScoreView"),
  },
  {
    path: "/analysis/mobile",
    name: "AnalysisMobile",
    component: () => import("@/views/Analysis/AnalysisMobileView"),
  },
  {
    path: "/analysis/offline",
    name: "AnalysisOffline",
    component: () => import("@/views/Analysis/AnalysisOfflineView"),
  },
];

analysisRoutes = analysisRoutes.map((route) => {
  route.meta = {
    siteDependent: true,
  };
  return route;
});

export { analysisRoutes };
