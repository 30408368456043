import * as auth from "@/api/auth";
import * as user from "@/api/user";
import { isMobileDevice } from "@/utils/common";
// import router from "@/router";

export default {
  state: {
    user: {},
    actions: {},
    access: {},
    isLoggedIn: false,
    isSSOUser: true,
    token: {
      idToken: "",
      refreshToken: "",
    },
    SSOLastLoginId: "",
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getActions(state) {
      return state.actions;
    },
    getToken(state) {
      return state.token.idToken;
    },
    getRefreshToken(state) {
      return state.token.refreshToken;
    },
    getTokenInfo(state) {
      return state.token;
    },
    getIsLoggedIn(state) {
      return state.isLoggedIn;
    },
    getAccess(state) {
      return state.access;
    },
    isSystemUser(state) {
      return state.user.type === "SYSTEM";
    },
    isSSOUser(state) {
      return state.isSSOUser;
    },
    getSSOLastLoginId(state) {
      return state.SSOLastLoginId;
    },
  },
  mutations: {
    CLEAR(state) {
      state.user = {};
      state.actions = {};
      state.access = {};
      state.token = {
        idToken: "",
        refreshToken: "",
      };
      state.isLoggedIn = false;
      state.isSSOUser = true;

      // 저장된 하자 검색이력 삭제
      for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).includes("defectSearchParam")) {
          localStorage.removeItem(localStorage.key(i));
        }
      }

      this.commit("CLEAR_MENU");
      this.commit("SET_MENUS", []);
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_ACTIONS(state, user) {
      state.actions = user;
    },
    SET_TOKEN(state, token) {
      state.token = {
        idToken: token.idToken,
        refreshToken: token.refreshToken,
        exp: token.exp,
      };

      state.isSSOUser = token.isSSOUser || false;
      state.isLoggedIn = true;
    },
    SET_ACCESS(state, access) {
      state.access = access;
    },
    SET_SSO_LAST_LOGIN_ID(state, id) {
      state.SSOLastLoginId = id;
    },
  },
  actions: {
    logOut({ state }) {
      /**
       * 우미 사용자, 매니저 + 시스템관리자에 따라 로그인, 로그아웃 페이지 분기
       *
       **/
      let elseSite =
        window.location.href.includes("manager") || // 매니저
        window.location.href.includes("partner") || // 공동시공사(231123)
        //window.location.href.includes("localhost") || // 로컬
        window.location.href.includes("-dev"); // 개발

      if (state.isSSOUser && !isMobileDevice() && !elseSite) {
        auth.logOut("Auth");
      } else {
        auth.logOut("Login");
      }
    },
    refreshUser({ state, commit, dispatch }) {
      if (state.token && state.token.idToken) {
        user.getUserInfo().then((res) => {
          // console.log("SET_USER", res.data);
          commit("SET_USER", res.data);
          dispatch("getUserActions", res.data.authorities);
        });
      }
    },
    getUserActions({ commit }, authorities) {
      user.getUserActions(authorities).then((res) => {
        let actions = {};
        res.data.content.forEach((action) => {
          if (action.method == "OPTIONS") {
            action.method = "EXPORT";
          }

          if (!Object.keys(actions).includes(action.menu.name)) {
            actions[action.menu.name] = {};
          }

          if (!Object.keys(actions[action.menu.name]).includes(action.method)) {
            actions[action.menu.name][action.method] = [];
          }

          actions[action.menu.name][action.method].push(action.url);
        });

        // console.log("SET_ACTIONS", actions);
        commit("SET_ACTIONS", actions);
      });
    },
    async checkAccess({ commit }, data) {
      return new Promise((resolve) => {
        user
          .checkUser(data)
          .then((res) => {
            commit("SET_ACCESS", res.data);
            resolve([res.data, false]);
          })
          .catch((err) => {
            commit("SET_ACCESS", {});
            resolve([err.response, true]);
          });
      });
    },
  },
};
