<template>
  <div>
    <v-progress-circular
      v-show="isLoading"
      :size="50"
      color="#004483"
      indeterminate
      fullscreen
      style="
        position: fixed;
        top: calc(50% - 16px);
        left: calc(50% - 16px);
        z-index: 100;
      "
    ></v-progress-circular>

    <!-- isTopLoading : api가 여러개인 페이지,로딩이 긴 api에 사용
         가장 위에 고정시켜서 표시
    -->
    <v-progress-circular
      v-show="isTopLoading"
      :size="50"
      color="#004483"
      indeterminate
      fullscreen
      style="
        position: fixed;
        top: calc(50% - 16px);
        left: calc(50% - 16px);
        z-index: 111;
      "
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  computed: {
    isLoading() {
      return this.$store.getters.getIsLoading;
    },
    isTopLoading() {
      return this.$store.getters.getIsTopLoading;
    },
  },
  created() {
    this.$store.commit("SET_LOADING", false);
    this.$store.commit("SET_IS_TOP_LOADING", false);
    //this.$store.commit("SET_LOADING_CLEAR");
  },
};
</script>

<style scoped>
.action {
  padding-top: 10px;
}
</style>
