<template>
  <v-btn
    class="dl export-button"
    @click="$emit('click')"
    outlined
    small
    elevation="0"
    :disabled="checkDisabled"
  >
    <v-icon class="export-icon" size="14">mdi-tray-arrow-down</v-icon>
    {{ text ? text : "내려받기" }}
  </v-btn>
</template>

<script>
export default {
  props: {
    text: String,
  },
  computed: {
    checkDisabled() {
      return !this.$funcs.checkAction(this.$route.name, "EXPORT");
    },
  },
};
</script>

<style scoped>
.export-button {
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-weight: 600 !important;
  background-color: transparent !important;
  height: 36px !important;
  font-size: 12px !important;
  letter-spacing: 0;
}
.export-icon {
  color: #004483;
  margin-right: 2px;
}
.btnSmall .export-button {
  height: 30px !important;
}
</style>
