import {
  AuthConnection,
  NoAuthConnection,
  getIds,
  CognitoTokenConnection,
} from "@/api/config.js";
import store from "@/store/index";
import { getToken } from "firebase/messaging";
import firebaseMessaging from "@/firebase.js";
import { isMobileDevice } from "@/utils/common.js";

export function getUserInfo() {
  return AuthConnection()
    .get(`/sys/users/info`)
    .then((res) => {
      try {
        if (!isMobileDevice()) {
          getDeviceToken().then((deviceToken) => {
            putUser(res.data.id, { fcmToken: deviceToken });
            console.log("deviceToken : ", deviceToken);
          });
        }
      } catch {
        console.log("deviceTokenNotFound");
      }

      // 비활성 계정 접근 제한.
      if (!res.data.activated) {
        console.log("user not activated");
        store.commit("SET_WARN", "비활성화된 계정입니다.");
        store.dispatch("logOut");
      }
      return res;
    });
}

export function getUserActions(data) {
  return AuthConnection().get(
    `/sys/users/actions?ids=${getIds(data)}&size=1000`
  );
}

export function putUser(id, data) {
  return AuthConnection().put(`/sys/users/${id}`, data);
}

export function checkUser(data) {
  return NoAuthConnection().post(`/open/users/check`, data);
}

export async function getDeviceToken() {
  return new Promise((resolve, reject) => {
    getToken(firebaseMessaging, { vapidKey: process.env.VUE_APP_FCM_KEY })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject("");
      });
  });
}

export async function getCognitoToken(code) {
  const params = {
    client_id: process.env.VUE_APP_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_REDIRECT_URI.replace("/callback", ""),
    grant_type: "authorization_code",
    code: code,
  };

  return CognitoTokenConnection().post(`/oauth2/token`, params);
}

export function serverLogin(info) {
  return new Promise((resolve, reject) =>
    getUserInfo()
      .then(({ data }) => {
        // console.log("LogedIn User :", data);

        // 로그인한 디바이스 토큰 업데이트
        if (info.fcmToken) {
          if (data.fcmToken == info.fcmToken) {
            delete info["fcmToken"];
          } else {
            data.fcmToken = info.fcmToken;
          }
        }

        // 변경사항 저장
        if (Object.keys(info).length) {
          putUser(data.id, info);
        }

        store.commit("SET_USER", data);
        resolve(data);
      })
      .catch((err) => {
        store.commit("CLEAR");
        reject(err);
      })
  );
}
