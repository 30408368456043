<template>
  <div class="STInputBox">
    <v-menu
      ref="menu"
      content-class="inputIn"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <input
          :class="isValid ? '' : 'notValid'"
          type="text"
          v-model="date"
          v-bind="attrs"
          v-on="on"
          @input="updateValue"
          placeholder="ex)2023-01-01"
        />
      </template>
      <v-date-picker
        v-model="date"
        no-title
        scrollable
        :day-format="(e) => new Date(e).getDate()"
        locale="KR"
        @change="menu = false"
        @input="updateValue"
        :max="max"
        :min="min"
      >
      </v-date-picker>
    </v-menu>
    <span class="icon">
      <v-icon>mdi-calendar</v-icon>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    min: String,
    max: String,
    value: String,
  },
  data() {
    return {
      date: "",
      menu: false,
      isValid: true,
    };
  },
  watch: {
    value(e) {
      this.date = e;
    },
  },
  methods: {
    updateValue(e) {
      let val = e;
      if (typeof e != "string") {
        val = e.target.value;
      }

      let result = this.$funcs.formatDate(val, false);
      val = result.val;
      this.isValid = result.isValid;

      // 최대일, 최소일 제한.
      if (this.isValid) {
        if (this.max && val > this.max) {
          val = this.max;
        }

        if (this.min && val <= this.min) {
          val = this.min;
        }
      }

      this.date = val;

      this.menu = false;
      this.$emit("input", val);
    },
  },
  created() {
    this.date = this.value;
  },
};
</script>

<style scoped>
.notValid {
  color: #f6654d !important;
}
</style>
