<template>
  <div data-app>
    <Snackbar />
    <Confirm />
    <Loading />
  </div>
</template>

<script>
import Snackbar from "@/components/Global/Snackbar.vue";
import Confirm from "@/components/Global/Confirm.vue";
import Loading from "@/components/Global/Loading.vue";
export default {
  components: { Snackbar, Confirm, Loading },
};
</script>
