<template>
  <div id="footer"></div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  components: {},
  methods: {},
};
</script>
