<template>
  <div class="siteSelect">
    <v-autocomplete
      v-model="selectedSite"
      :items="sites"
      :disabled="siteDisabled"
      auto-select-first
      dense
      class="autocomplete"
      hide-details
      return-object
      item-text="name"
      no-data-text="검색된 내용이 없습니다."
      @change="refresh"
      :menu-props="{ closeOnClick: true }"
      clearable
    ></v-autocomplete>
  </div>
</template>

<script>
import { getSites } from "@/api/master";

export default {
  name: "SiteSelector",
  props: ["isAll"],
  data() {
    return {
      selectedSite: {},
      sites: [],
    };
  },
  watch: {
    // selectedSite(val, preVal) {
    //   console.log("watch selectedSite", val, preVal);
    //   if (val) {
    //     this.$store.commit("SET_SITE", val);
    //     this.selectedSite = val;
    //
    //     //초기화중에 refresh 하지 않도록 preVal.id
    //     if (preVal?.id) {
    //       // refresh-button을 찾아서 클릭해줌
    //       if (this.$parent.$el.getElementsByClassName("refresh-button")) {
    //         this.$parent.$el
    //           .getElementsByClassName("refresh-button")[0]
    //           .click();
    //       } else {
    //         this.$router.go(); // 원래 사용하던 방식
    //       }
    //     }
    //   }
    // },
  },
  computed: {
    site() {
      return this.$store.getters.getSite;
    },
    user() {
      return this.$store.getters.getUser;
    },
    access() {
      return this.$store.getters.getAccess;
    },
    siteDisabled() {
      return !this.$route.meta.siteDependent;
    },
    isDefect() {
      if (this.isAll) return null;
      else return true;
    },
  },
  methods: {
    menuOpen() {
      this.$emit("SetopenMenu");
    },
    getSitesAPI() {
      let query = { size: 1000, sort: "name", isDefect: this.isDefect };
      getSites(query).then((res) => {
        this.sites = res.data.content;
        const selectedSiteExists = this.sites.find(
          (site) => site.id == this.site.id
        );
        if (this.site.id && selectedSiteExists) {
          //console.log("this.selectedSite = selectedSiteExists;");
          this.selectedSite = selectedSiteExists;
          this.$store.commit("SET_SITE", selectedSiteExists);
        } else {
          //console.log("this.selectedSite = this.sites[0];");
          this.selectedSite = this.sites[0];
          this.$store.commit("SET_SITE", this.sites[0]);
        }
      });
    },
    logOut() {
      this.$store.dispatch("logOut");
    },
    siteRefresh(){
      // 외부 컴포넌트에서 현장 수정 시, 강제 refresh
      this.getSitesAPI();
    },
    refresh(e) {
      //console.log(e);
      if (!e) return;

      if (this.selectedSite) {
        //console.log("change event refresh");
        this.$store.commit("SET_SITE", this.selectedSite);

        if (this.$parent.$el.getElementsByClassName("refresh-button")) {
          this.$parent.$el.getElementsByClassName("refresh-button")[0].click();
        } else {
          this.$router.go(); // 원래 사용하던 방식
        }
      }
    },
  },
  created() {
    this.getSitesAPI();
  },

  activated() {
    //console.log("activated", this.selectedSite.name);
    //console.log(this.site.name);
    if (
      this.selectedSite.id &&
      this.site.id &&
      this.selectedSite.id != this.site.id
    ) {
      this.selectedSite = this.site;
      this.refresh(true);
    }
  },
};
</script>

<style scoped>
.autocomplete {
  color: #004483;
  font-size: 18px !important;
  font-weight: 400 !important;
  width: 400px;
  margin-left: 10px;
  padding-right: 20px;
}
</style>
