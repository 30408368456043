import * as commonApi from "@/api/common";

export default {
  state: {
    enums: {},
    enumTitleMaps: {},
    enumCodeMaps: {},
    site: {}, // 현재 선택한 현장정보.
    snackbar: {},
    confirm: {},
    openConfirm: false,
    isLoading: false,
    loadingCnt: 0,
    isTopLoading: false, // 강제로딩
    notificationNo: false,
    favorites: [], // 즐겨찾기 메뉴

    menus: [], //전체 메뉴 목록
  },
  getters: {
    getEnums(state) {
      return state.enums;
    },
    getEnumTitleMaps(state) {
      return state.enumTitleMaps;
    },
    getEnumCodeMaps(state) {
      return state.enumCodeMaps;
    },
    getSite(state) {
      return state.site;
    },
    getSnackBar(state) {
      return state.snackbar;
    },
    getConfirm(state) {
      return state.confirm;
    },
    getOpenConfirm(state) {
      return state.openConfirm;
    },
    getIsLoading(state) {
      return state.isLoading;
    },
    getIsTopLoading(state) {
      return state.isTopLoading;
    },
    getNotificationNo(state) {
      return state.notificationNo;
    },
    getFavorites(state) {
      return state.favorites;
    },
    getMenus(state) {
      return state.menus;
    },
    getReqPath(state) {
      return state.reqPath;
    },
  },
  mutations: {
    SET_ENUMS(state, payload) {
      state.enums = payload;

      /** enum 효율적 활용을 위해 title, code 각각 기준 map 생성
       *
       * ex) enums = {CallStatus:[{...}]}
       * ex) enumTitleMaps = {CallStatus:{받기전:"BEFORE_HANDLE"}}
       * ex) enumCodeMaps = {CallStatus:{BEFORE_HANDLE:"받기전"}}
       *
       **/
      let titles = {};
      let codes = {};
      Object.keys(payload).forEach((key) => {
        titles[key] = {};
        codes[key] = {};
        payload[key].forEach((item) => {
          titles[key][item.title] = item.code;
          codes[key][item.code] = item.title;
        });
      });

      state.enumTitleMaps = titles;
      state.enumCodeMaps = codes;
    },
    SET_SITE(state, payload) {
      state.site = payload;
    },
    SET_INFO(state, payload) {
      state.snackbar = { type: "INFO", text: payload, show: true };
    },
    SET_SUCCESS(state, payload) {
      state.snackbar = { type: "SUCCESS", text: payload, show: true };
    },
    SET_WARN(state, payload) {
      state.snackbar = { type: "WARN", text: payload, show: true };
    },
    SET_EMPTY(state) {
      state.snackbar = { type: "", text: "", show: false };
    },
    SET_CONFIRM(state, confirm) {
      if (confirm) {
        state.confirm = confirm;
        state.openConfirm = true;
      } else {
        state.openConfirm = false;
      }
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
      // if (payload) {
      //   state.loadingCnt++;
      //   state.isLoading = payload;
      // } else if (state.loadingCnt) {
      //   state.loadingCnt--;
      //   state.isLoading = state.loadingCnt;
      // }
      // console.log(state.loadingCnt, payload);
    },
    SET_LOADING_CLEAR(state) {
      state.loadingCnt = 0;
      state.isLoading = false;
    },
    SET_IS_TOP_LOADING(state, payload) {
      state.isTopLoading = payload;
    },
    SET_NOTIFICATION_NO(state, payload) {
      state.notificationNo = payload;
    },
    SER_FAVORITES(state, payload) {
      state.favorites = payload;
    },
    SET_MENUS(state, menus) {
      state.menus = menus;
    },
    SET_REQ_PATH(state, reqPath) {
      state.reqPath = reqPath;
    },
  },
  actions: {
    refreshEnums({ commit }) {
      commonApi.getEnums().then((res) => {
        commit("SET_ENUMS", res.data);
      });
    },
  },
};
