var csRoutes = [
  {
    path: "/cs/call",
    name: "CallView",
    component: () => import("@/views/Cs/CallView"),
  },
  {
    path: "/cs/ticket",
    name: "TicketView",
    component: () => import("@/views/Cs/TicketView"),
  },
  {
    path: "/cs/ticketList",
    name: "TicketListView",
    component: () => import("@/views/Cs/TicketListView"),
  },
  {
    path: "/cs/surveyList/:type",
    name: "SurveyList",
    component: () => import("@/views/Cs/SurveyListView"),
  },
  {
    path: "/cs/surveyTemplate",
    name: "SurveyTemplate",
    component: () => import("@/views/Cs/SurveyTemplateView"),
  },
  {
    path: "/cs/surveyPost",
    name: "SurveyPost",
    component: () => import("@/views/Cs/SurveyPostView"),
  },
  {
    path: "/cs/surveyForm/:survey_id",
    name: "SurveyForm",
    meta: {
      noLayout: true,
      openURL: true,
    },
    component: () => import("@/views/Cs/SurveyFormView"),
  },
  {
    path: "/cs/surveyResult",
    name: "SurveyResult",
    component: () => import("@/views/Cs/SurveyResultView"),
  },
  {
    path: "/cs/surveyTargets",
    name: "SurveyTargets",
    component: () => import("@/views/Cs/SurveyTargetView"),
  },
  {
    path: "/cs/repairServiceList",
    name: "RepairServiceList",
    component: () => import("@/views/Cs/RepairServiceListView"),
  },
  {
    path: "/cs/repairService",
    name: "RepairService",
    component: () => import("@/views/Cs/RepairServiceView"),
  },
];

export { csRoutes };
