<template>
  <v-app-bar :class="headerClass" color="#ffffff" height="48px">
    <v-app-bar-nav-icon @click="menuOpen()" v-if="isSm" class="mSetBtn"></v-app-bar-nav-icon>
    <Tabs />
    <!-- <Tabs v-show="!isMobileDevice" /> -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-show="openTabs.length > 1" v-bind="attrs" v-on="on" icon color="gray" @click="allClose()"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </template>
      <span>모든 탭 닫기</span>
    </v-tooltip>
    <v-spacer></v-spacer>
    <v-menu bottom min-width="200px" rounded offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" class="mSetBtn">
          <v-avatar :color="colorById" size="32">
            <span class="white--text text-h7">{{ initials }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center">
            <v-avatar :color="colorById">
              <span class="white--text text-h5">{{ initials }}</span>
            </v-avatar>
            <h3 class="mt-2">
              {{ user.mobile ? user.mobile : "" }}
            </h3>
            <h3 class="mt-2">
              {{ user.email ? user.email : "" }}
            </h3>
            <p class="text-caption mt-1">{{ user.name }}님</p>
            <!-- <v-btn depressed rounded text> Edit Account </v-btn> -->
            <v-divider class="my-3"></v-divider>
            <v-btn depressed rounded text @click="logOut()"> 로그아웃 </v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Tabs from "@/components/Layout/Tabs.vue";

export default {
  name: "Header",
  props: ["isSm"],
  components: { Tabs },
  data() {
    return {
      tab: null,
      items: ["web", "shopping", "videos", "images", "news"],
      color_list: [
        "blue-grey darken-3",
        "red accent-2",
        "pink",
        "red lighten-3",
        "pink lighten-2",
        "deep-purple ",
        "pink darken-2",
        "primary",
        "orange lighten-1",
        "grey darken-1",
        "purple lighten-2",
        "red lighten-2",
        "purple accent-4",
        "blue lighten-1",
        "deep-purple lighten-2",
        "deep-purple accent-2",
        "indigo lighten-2",
        "indigo accent-3",
        "light-blue lighten-2",
        "cyan lighten-1",
        "cyan darken-3",
        "cyan accent-4",
        "teal lighten-3",
        "teal",
        "green lighten-2",
        "green darken-1",
        "lime darken-1",
        "orange",
        "yellow darken-2",
        "brown lighten-2",
        "blue-grey darken-1",
        "grey darken-3",
      ],
    };
  },
  computed: {
    ...mapGetters({
      openTabs: "getOpenTabs",
    }),
    user() {
      return this.$store.getters.getUser;
    },
    headerClass() {
      if (this.isSm) {
        return "smAppBar";
      } else {
        return "elevation5 appBar";
      }
    },
    initials() {
      let name = this.user?.name;

      if (name) {
        return name.slice(0, 1);
      }
      return "";
    },
    colorById() {
      return this.color_list[this.user.id % this.color_list.length];
    },
    isMobileDevice() {
      return this.$funcs.isMobileDevice();
    },
  },
  methods: {
    ...mapActions(["allTabClose"]),
    menuOpen() {
      this.$emit("SetopenMenu");
    },

    logOut() {
      if (window.location.href.includes(process.env.VUE_APP_WM_CSM_URL)) {
        // window.location.href = process.env.VUE_APP_WM_LOGIN_URL;
        this.$store.dispatch("logOut", true);
      } else {
        this.$store.dispatch("logOut", true);
      }
    },
    allClose() {
      this.allTabClose(this.$route);
    },
  },
  created() {},
};
</script>

<style scoped>
.autocomplete {
  color: #004483;
  margin-bottom: 5px;
  font-size: 14px;
}
.appBar {
  margin-left: 300px;
  width: calc(100% - 300px);
  position: absolute;
  z-index: 2;
  transition:0.25s;
}
.appBar.on {
  margin-left:48px;
  width:calc(100% - 48px);
}
.smAppBar {
  margin-left: 0px;

  width: 100%;
  position: absolute;
  z-index: 2;
}
.elevation5 {
  box-shadow: 0 1px 10px -1px rgba(85, 85, 85, 0.08), 0 2px 30px 0 rgba(85, 85, 85, 0.06),
    0 1px 30px 0 rgba(85, 85, 85, 0.03) !important;
}
</style>
