var disputeRoutes = [
  {
    path: "/dispute/lawsuit",
    name: "LawsuitView",
    component: () => import("@/views/Dispute/LawsuitView"),
  },
  {
    path: "/dispute/review",
    name: "ReviewView",
    component: () => import("@/views/Dispute/ReviewView"),
  },
  {
    path: "/dispute/bill",
    name: "BillView",
    component: () => import("@/views/Dispute/BillView"),
  },
];

export { disputeRoutes };
