<template>
  <div id="DSMainBox" data-app>
    <LeftMenu :isSm="isSm" :open="isMenu" @SetmenuClose="SetClose" @toggleBox="togOnBox" />
    <Header :isSm="isSm" @SetopenMenu="SetOpen" :class="{on: togBox === true}" />

    <div id="DSinBox" :class="[isSm ? 'smContent' : 'content',{on: togBox === true}]">
      <div class="mainAllBox">
        <v-card
          :class="{ subAllItems: true, elevation5: true, 'content-card': isSm }"
        >
          <keep-alive :include="cashedValues">
            <router-view :key="$route.fullPath" />
          </keep-alive>
        </v-card>
      </div>
      <Footer />
    </div>
    <CallPopup :class="{ on: call }" @close="callClose" :callData="callData" />
  </div>
</template>

<script>
import "@/assets/overrides.sass";
import LeftMenu from "@/components/Layout/LeftMenu.vue";
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";
import CallPopup from "@/components/Cs/CallPopup.vue";
import { mapGetters } from "vuex";
import { onMessage, isSupported } from "firebase/messaging";

export default {
  name: "Layout",
  components: { LeftMenu, Header, Footer, CallPopup },
  data() {
    return {
      isMenu: false,
      togBox: false,
      call: false,
      callData: {},
      callTo: null,
      windowHeight: 0,
      windowWidth: 0,
    };
  },
  computed: {
    ...mapGetters({
      openTabs: "getOpenTabs",
      cashedValues: "getCashedValues",
    }),
    isSm() {
      return this.windowWidth < 960;
    },
  },
  methods: {
    SetOpen() {
      this.isMenu = true;
    },
    SetClose() {
      this.isMenu = false;
    },
    togOnBox() {
      this.togBox = !this.togBox;
    },
    callClose() {
      this.call = false;

      // 전화 수신/발신 현황 페이지가 열려있는 경우, 컴포넌트를 찾아 새로고침해준다.
      if (this.$route.path == "/cs/call") {
        var callComp = this.$children.find((x) => x.$vnode.key == "/cs/call");
        if (callComp) {
          callComp.$children[0].refresh();
        }
      }
    },
    messageEvent(payload) {
      this.callData = payload.data;
      this.call = true;
    },

    // Notification
    checkOrAllowNotification() {
      Notification.requestPermission().then((permission) => {
        // console.log("permission", permission);

        if (permission !== "granted") {
          isSupported().then((supported) => {
            console.log("Firbase Messaging Supported : ", supported);
            if (supported) {
              if (!this.$store.getters.getNotificationNo) {
                this.$store.commit("SET_CONFIRM", {
                  text: `전화 수신 알림을 위해 브라우저 알림을 허용해주세요. 알림 설정 가이드 페이지를 확인하시겠습니까? `,
                  yes: this.openGuide,
                  no: this.clickNo,
                });
              }
            } else {
              this.$store.commit(
                "SET_WARN",
                "전화 수신 알림이 지원되지 않는 브라우저 입니다."
              );
            }
          });
        }
      });
    },
    getUrlByBrowser() {
      const urls = {
        chrome:
          "https://support.google.com/chrome/answer/3220216?hl=ko&co=GENIE.Platform%3DAndroid",
        firefox: "https://support.mozilla.org/ko/kb/push-notifications-firefox",
        safari: "https://support.apple.com/ko-kr/guide/safari/sfri40734/mac",
        opera:
          "https://support.google.com/chrome/answer/3220216?hl=ko&co=GENIE.Platform%3DAndroid",
        edge: "https://support.microsoft.com/ko-kr/microsoft-edge/microsoft-edge%EC%97%90%EC%84%9C-%EC%9B%B9-%EC%82%AC%EC%9D%B4%ED%8A%B8-%EC%95%8C%EB%A6%BC-%EA%B4%80%EB%A6%AC-0c555609-5bf2-479d-a59d-fb30a0b80b2b",
      };

      let userAgent = navigator.userAgent;
      let browser;

      if (userAgent.match(/edg/i)) {
        browser = "edge";
      } else if (userAgent.match(/chrome|chromium|crios/i)) {
        browser = "chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browser = "firefox";
      } else if (userAgent.match(/safari/i)) {
        browser = "safari";
      } else if (userAgent.match(/opr\//i)) {
        browser = "opera";
      } else {
        browser = "";
      }
      return urls[browser] || "";
    },
    openGuide() {
      window.open(this.getUrlByBrowser());
    },
    clickNo() {
      this.$store.commit("SET_NOTIFICATION_NO", true);
    },

    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
  },
  created() {
    this.onResize();

    this.checkOrAllowNotification();

    navigator.serviceWorker.addEventListener("onBackgroundMessage", (event) => {
      console.log("Push Notification Recieved", event); // refresh code goes here
    });

    //onBackgroundMessage에서 전달되어 옴
    const channel = new window.BroadcastChannel("sw-messages");

    channel.addEventListener("message", (e) => {
      this.messageEvent(e.data);
    });

    onMessage(this.$messaging, (payload) => {
      console.log("Message received////layout. ", payload);
      this.messageEvent(payload);
    });
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.content {
  margin-top: 48px !important;
  margin-left: 300px !important;
}
.smContent {
  margin-top: 0px !important;
  margin-left: 0px !important;
}
.elevation5 {
  box-shadow: 0 1px 10px -1px rgba(85, 85, 85, 0.08),
    0 2px 30px 0 rgba(85, 85, 85, 0.06), 0 1px 30px 0 rgba(85, 85, 85, 0.03) !important;
}
.content-card {
  overflow: auto;
}
</style>
