<template>
  <div class="textBox">
    <label for="textBox-01">{{ text }}</label>
    <input
      id="textBox-01"
      type="text"
      :value="value"
      @input="updateValue"
      :style="`width:${width}px;`"
    />
  </div>
  <!--  <v-text-field
    class="mb-2 mr-2"
    :value="value"
    @input="updateValue"
    :style="`width:${width}px;`"
    :label="text"
    outlined
    dense
    hide-details
  /> -->
</template>

<script>
export default {
  props: {
    value: String,
    text: String,
    width: {
      default: 150,
    },
  },
  methods: {
    updateValue(e) {
      this.$emit("input", e.target.value);
      // this.$emit("input", e);
    },
  },
};
</script>

<style scoped>
/*.v-text-field--outlined fieldset {
  border-radius: 5px !important;
  border-bottom: inherit !important;
}*/

.textBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-right: 20px;
  margin-bottom: 10px;
  position: relative;
}
input {
  border: 1px solid #004483;
  border-radius: 0 5px 5px 0;
  outline: none;
  transition: 0.25s;
  height: 30px;
  padding: 0 10px;
  background-color: #ffffff;
  appearance: auto;
  /*  width: auto;*/
  font-weight: 600;
  font-size: 14px;
}
label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px 0 0 5px;
  background-color: #004483;
  color: #ffffff;
}
</style>
