import Vue from "vue";
import RefreshButton from "@/components/Global/RefreshButton.vue";
import ExportButton from "@/components/Global/ExportButton.vue";
import UploadButton from "@/components/Global/UploadButton.vue";
import CommonButton from "@/components/Global/CommonButton.vue";
import SiteSelector from "@/components/Global/SiteSelector.vue";
import SearchTextBox from "@/components/Global/SearchTextBox.vue";
import DatePicker from "@/components/Global/DatePicker.vue";
import TimePicker from "@/components/Global/TimePicker.vue";

Vue.component("RefreshButton", RefreshButton);
Vue.component("ExportButton", ExportButton);
Vue.component("UploadButton", UploadButton);
Vue.component("CommonButton", CommonButton);
Vue.component("SiteSelector", SiteSelector);
Vue.component("SearchTextBox", SearchTextBox);
Vue.component("DatePicker", DatePicker);
Vue.component("TimePicker", TimePicker);
