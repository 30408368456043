var siteRoutes = [
  {
    path: "/site/hos",
    name: "SiteHo",
    component: () => import("@/views/Site/SiteHoView"),
  },
  {
    path: "/site/partners",
    name: "SitePartner",
    component: () => import("@/views/Site/SitePartnerView"),
  },
  {
    path: "/site/areapartmaps",
    name: "SiteAreaPartMap",
    component: () => import("@/views/Site/SiteAreaPartMapView"),
  },
  {
    path: "/site/defectitems",
    name: "SiteDefectItem",
    component: () => import("@/views/Site/SiteDefectItemView"),
  },
  {
    path: "/site/takeoveritems",
    name: "SiteTakeOveritem",
    component: () => import("@/views/Site/SiteTakeOverItemView"),
  },
  {
    path: "/site/timetable",
    name: "SiteTimeTable",
    component: () => import("@/views/Site/SiteTimeTableView"),
  },
  {
    path: "/site/managers",
    name: "SiteManager",
    component: () => import("@/views/Site/SiteManagerView"),
  },
  {
    path: "/site/managers/:type",
    name: "SiteManager",
    component: () => import("@/views/Site/SiteManagerView"),
  },
  {
    path: "/site/workers",
    name: "SiteWorker",
    component: () => import("@/views/Site/SiteWorkerView"),
  },
  {
    path: "/site/warranties",
    name: "SiteWarranty",
    component: () => import("@/views/Site/SiteWarrantyView"),
  },
  {
    path: "/site/takeoverdocs",
    name: "SiteTakeOverDocsView",
    component: () => import("@/views/Site/SiteTakeOverDocsView"),
  },
  {
    path: "/site/movein",
    name: "SiteMoveIn",
    component: () => import("@/views/Site/SiteMoveInView"),
  },
  {
    path: "/site/homeDocs",
    name: "SiteHomeDocsView",
    component: () => import("@/views/Site/SiteHomeDocsView"),
  },
];

siteRoutes = siteRoutes.map((route) => {
  route.meta = {
    siteDependent: true,
  };
  return route;
});

export { siteRoutes };
