var partnersRoutes = [
  // 협력업체 페이지
  {
    path: "/partners",
    name: "PartnersDefectView",
    component: () => import("@/views/Partners/PartnersDefectView"),
    meta: {
      noLayout: true,
      openURL: true,
    },
    beforeEnter: function(to, from, next) {
      const allowedReferrers = process.env.VUE_APP_WM_PARTNER_LINK_ALLOW_REFERRER_URL;
      let isAllowedReferrer = true;
      if (allowedReferrers != null) {
        const referrer = document.referrer || '';
        isAllowedReferrer = allowedReferrers.split(',').some((allowedReferrer) => referrer.startsWith(allowedReferrer.trim()));
      }
      if (isAllowedReferrer) {
        next();
      } else {
        alert('계약 업체가 아닐 경우 접속할 수 없습니다.');
        window.close();
      }
    },
  },
  {
    path: "/partners/detail",
    name: "PartnersDefectView",
    component: () => import("@/views/Partners/PartnersDetailView"),
    meta: {
      noLayout: true,
      openURL: true,
    },
  },
];

export { partnersRoutes };
