<template>
  <div class="STInputBox">
    <v-menu
      ref="menu"
      content-class="inputIn"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <input
          :class="isValid ? '' : 'notValid'"
          type="text"
          v-model="time"
          v-bind="attrs"
          v-on="on"
          @input="updateValue"
          placeholder="ex)14:00"
        />
      </template>

      <v-time-picker
        v-if="menu"
        v-model="visitAtTime"
        full-width
        @click:minute="
          $refs.menu.save(time);
          menu = false;
        "
        @input="updateValue"
        format="24hr"
      ></v-time-picker>
    </v-menu>
    <span class="icon">
      <v-icon>mdi-clock-outline</v-icon>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    max: String,
    value: String,
  },
  data() {
    return {
      time: "",
      menu: false,
      isValid: true,
    };
  },
  watch: {
    value(e) {
      this.time = e;
    },
  },
  methods: {
    validatate() {
      return false;
    },
    updateValue(e) {
      let val = e;
      if (typeof e != "string") {
        val = e.target.value;
      }

      let result = this.$funcs.formatTime(val, false);
      val = result.val;
      this.isValid = result.isValid;

      this.time = val;

      this.menu = false;
      this.$emit("input", val);
    },
  },
  created() {
    this.time = this.value;
  },
};
</script>

<style scoped>
.notValid {
  color: #f6654d !important;
}
</style>
