export default {
  /**
   * menu tab 관련
   *  -기본적으로 메뉴관리에 저장된 path로 이동
   *  -query를 포함하는 페이지들이 있어 탭 활성화/구분은 fullPath 사용
   *  -캐시는 메뉴관리에 저장한 컴포넌트명(View page)+fullPath key로 저장됨
   */
  state: {
    isLayout: false, //메뉴,탭 사용 여부

    pages: [], //전체 페이지 목록 (tab 표시 관련 사용)

    openTabs: [], // 열려있는 tab들 {path:.., fullPath:..}
    cashedValues: [], //탭 내용 캐시 용도로 사용되는 컴포넌트 명들
  },
  getters: {
    isLayout(state) {
      return state.isLayout;
    },

    getPages(state) {
      return state.pages;
    },
    getOpenTabs(state) {
      return state.openTabs;
    },
    getCashedValues(state) {
      return state.cashedValues;
    },
  },
  mutations: {
    CLEAR_MENU(state) {
      state.pages = [];
      state.openTabs = [];
      state.cashedValues = [];
    },
    SET_ISLAYOUT(state, payload) {
      state.isLayout = payload;
    },

    SET_PAGES(state, pages) {
      pages.push({ url: "/", title: "대시보드" });
      state.pages = pages;
    },
    SET_CASHEDVALUES(state, value) {
      state.cashedValues = value;
    },
    SET_OPENTABS(state, tab) {
      state.openTabs.push(tab);
      this.dispatch("setCashedValues");
    },
    DEL_OPENTABS(state, tab) {
      let idx = state.openTabs.findIndex((item) => {
        return item.fullPath == tab;
      });
      if (idx > -1) state.openTabs.splice(idx, 1);
      this.dispatch("setCashedValues");
    },
  },
  actions: {
    setCashedValues({ commit }) {
      // console.log(this);
      var pages = this.getters.getPages;
      var openTabs = this.getters.getOpenTabs;
      var openTabsPath = openTabs.map((x) => x.path);
      var filteredValues = pages.filter((item) =>
        openTabsPath.includes(item.url)
      );

      commit(
        "SET_CASHEDVALUES",
        filteredValues.map((x) => x.name)
      );
    },
    allTabClose(context, payload) {
      // 현재 탭을 제외하고 모든 탭을 닫는다.
      context.state.openTabs = [];
      var tab = {
        fullPath: payload.fullPath,
        path: payload.path,
      };
      context.commit("SET_OPENTABS", tab);
    },
  },
};
