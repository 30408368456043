import { AuthConnection, toQueries, getIds } from "@/api/config.js";

const defaultAlert = true;

/** 하자 현장관리 APIs */
export function postSitesList(data) {
  return AuthConnection(defaultAlert).post(`/defect/sites/list`, data);
}
export function getSites(params) {
  return AuthConnection().get(`/defect/sites?${toQueries(params)}`);
}
export function getSiteList(params) {
  return AuthConnection().get(`/defect/site/list?${toQueries(params)}`);
}
export function deleteSiteList(data) {
  return AuthConnection(defaultAlert).delete(
    `/defect/sites/list?ids=${getIds(data)}`
  );
}
export function updateSitesByERP() {
  return AuthConnection().post(`/defect/sites/erp-update`);
}
export function saveDongCorporation(data) {
  return AuthConnection(defaultAlert).post(`/defect/dong/corp`, data);
}
export function getConstructor() {
  return AuthConnection().get(`/defect/constructor`);
}

/** 공종관리 APIs */
export function postWorkKinds(data) {
  return AuthConnection(defaultAlert).post(`/defect/workkinds`, data);
}
export function postWorkKindList(data) {
  return AuthConnection(defaultAlert).post(`/defect/workkinds/list`, data);
}
export function getWorkKinds(params) {
  return AuthConnection().get(`/defect/workkinds?${toQueries(params)}`);
}
export function putWorkKinds(id, data) {
  return AuthConnection(defaultAlert).put(`/defect/workkinds/${id}`, data);
}
export function deleteWorkKinds(id, data) {
  return AuthConnection(defaultAlert).delete(`/defect/workkinds/${id}`, data);
}
export function deleteWorkKindList(data) {
  return AuthConnection(defaultAlert).delete(
    `/defect/workkinds/list?ids=${getIds(data)}`
  );
}

/** 실 관리 APIs */
export function postAreas(data) {
  return AuthConnection(defaultAlert).post(`/defect/areas`, data);
}
export function postAreaList(data) {
  return AuthConnection(defaultAlert).post(`/defect/areas/list`, data);
}
export function getAreas(params) {
  return AuthConnection().get(`/defect/areas?${toQueries(params)}`);
}
export function putAreas(id, data) {
  return AuthConnection(defaultAlert).put(`/defect/areas/${id}`, data);
}
export function deleteAreas(id, data) {
  return AuthConnection(defaultAlert).delete(`/defect/areas/${id}`, data);
}
export function deleteAreaList(data) {
  return AuthConnection(defaultAlert).delete(
    `/defect/areas/list?ids=${getIds(data)}`
  );
}

/** 부위 관리 APIs */
export function postParts(data) {
  return AuthConnection(defaultAlert).post(`/defect/parts`, data);
}
export function postPartList(data) {
  return AuthConnection(defaultAlert).post(`/defect/parts/list`, data);
}
export function getParts(params) {
  return AuthConnection().get(`/defect/parts?${toQueries(params)}`);
}
export function putParts(id, data) {
  return AuthConnection(defaultAlert).put(`/defect/parts/${id}`, data);
}
export function deleteParts(id, data) {
  return AuthConnection(defaultAlert).delete(`/defect/parts/${id}`, data);
}
export function deletePartList(data) {
  return AuthConnection(defaultAlert).delete(
    `/defect/parts/list?ids=${getIds(data)}`
  );
}

/** 상세부위 관리 APIs */
export function postPartDetails(data) {
  return AuthConnection(defaultAlert).post(`/defect/partdetails`, data);
}
export function postPartDetailList(data) {
  return AuthConnection(defaultAlert).post(`/defect/partdetails/list`, data);
}
export function getPartDetails(params) {
  return AuthConnection().get(`/defect/partdetails?${toQueries(params)}`);
}
export function putPartDetails(id, data) {
  return AuthConnection(defaultAlert).put(`/defect/partdetails/${id}`, data);
}
export function deletePartDetails(id, data) {
  return AuthConnection(defaultAlert).delete(`/defect/partdetails/${id}`, data);
}
export function deletePartDetailList(data) {
  return AuthConnection(defaultAlert).delete(
    `/defect/partdetails/list?ids=${getIds(data)}`
  );
}

/** 실, 부위 매핑 APIs */
export function getAreaPartMaps(params) {
  return AuthConnection().get(`/defect/area-part-maps?${toQueries(params)}`);
}

export function postAreaPartMapList(data) {
  return AuthConnection().post(`/defect/area-part-maps/list`, data);
}

export async function deleteAllAreaPartMaps() {
  return await AuthConnection(defaultAlert).delete(
    `/defect/area-part-maps/all`
  );
}

export function getPartDetailMaps(params) {
  return AuthConnection().get(`/defect/part-detail-maps?${toQueries(params)}`);
}

export function postPartDetailMapList(data) {
  return AuthConnection(defaultAlert).post(
    `/defect/part-detail-maps/list`,
    data
  );
}

export async function deleteAllPartDetailsMaps() {
  return await AuthConnection(defaultAlert).delete(
    `/defect/part-detail-maps/all`
  );
}

/** 인수인계 품목 관리 APIs */
export function postTakeOverItems(data) {
  return AuthConnection(defaultAlert).post(`/defect/takeoveritems`, data);
}
export function postTakeOverItemList(data) {
  return AuthConnection(defaultAlert).post(`/defect/takeoveritems/list`, data);
}
export function getTakeOverItems(params) {
  return AuthConnection().get(`/defect/takeoveritems?${toQueries(params)}`);
}
export function putTakeOverItems(id, data) {
  return AuthConnection(defaultAlert).put(`/defect/takeoveritems/${id}`, data);
}
export function deleteTakeOverItems(id, data) {
  return AuthConnection(defaultAlert).delete(
    `/defect/takeoveritems/${id}`,
    data
  );
}
export function deleteTakeOverItemList(data) {
  return AuthConnection(defaultAlert).delete(
    `/defect/takeoveritems/list?ids=${getIds(data)}`
  );
}

/** 민원접수 전화번호 관리 APIs */
export function getCsNumbers(params) {
  return AuthConnection().get(`/defect/csNumber/list?${toQueries(params)}`);
}

export function postCsNumbers(data) {
  return AuthConnection(defaultAlert).post(`/defect/csNumber/list`, data);
}

export function deleteCsNumbers(data) {
  return AuthConnection(defaultAlert).delete(
    `/defect/csNumber/list?ids=${getIds(data)}`
  );
}

export function getCsNumberUsers(id) {
  return AuthConnection().get(`/defect/csNumber/${id}/users`);
}

export function postCsNumberUsers(id, data) {
  return AuthConnection(defaultAlert).post(
    `/defect/csNumber/${id}/users`,
    data
  );
}

export function deleteCsNumberUsers(data) {
  return AuthConnection(defaultAlert).delete(
    `/defect/csNumberUser/list?ids=${getIds(data)}`
  );
}

export function updatePassword(id, data) {
  return AuthConnection(defaultAlert).put(`/defect/csNumber/${id}/pw`, data);
}

/** 하자 인수인계 문서 관리 APIs */
export function getTakeOverDocs(params) {
  return AuthConnection().get(`/defect/takeoverdocs?${toQueries(params)}`);
}
export function postTakeOverDocs(data) {
  return AuthConnection(defaultAlert).post(`/defect/takeoverdocs/list`, data);
}

export function deleteTakeOverDocsList(data) {
  return AuthConnection(defaultAlert).delete(
    `/defect/takeoverdocs/list?ids=${getIds(data)}`
  );
}

/** 하자 원인 관리 APIs */
export function getAllCauses(params) {
  return AuthConnection().get(`/defect/cause/list?${toQueries(params)}`);
}
export function postCauses(data) {
  return AuthConnection(defaultAlert).post(`/defect/cause/list`, data);
}
export function deleteCauses(data) {
  return AuthConnection(defaultAlert).delete(
    `/defect/cause/list?ids=${getIds(data)}`
  );
}
export function getCauseByPartDetail(id) {
  return AuthConnection().get(`/defect/cause/${id}`);
}
export async function deleteDetailCauseMap(data) {
  return AuthConnection(defaultAlert).delete(
    `/defect/cause/map/list?ids=${getIds(data)}`
  );
}
export async function deleteDetailCauseMapById(id) {
  return AuthConnection(defaultAlert).delete(`/defect/cause/map?id=${id}`);
}
export async function postDetailCauseMap(data) {
  return AuthConnection(defaultAlert).post(`/defect/cause/map`, data);
}
export function postDetailCausesListByPartDetail(data, id) {
  return AuthConnection(defaultAlert).post(`/defect/cause/map/${id}`, data);
}
