/** ERP 등 외부에서 접속하는 라우터 */

var openRoutes = [
  // erp 외부에서 접속하는 세대 페이지
  {
    path: "/site/hoList/:code",
    name: "SiteHoOpen",
    component: () => import("@/views/Site/SiteHoOpenView"),
    meta: {
      noLayout: true,
      //openURL: true,
      siteDependent: false,
    },
  },

  // open page는 아니나.. siteDependent 때문에 site.js에 저장할 수 없으므로 위치
  {
    path: "/site/sign-complete",
    name: "SiteHoSignCompleteView",
    meta: {
      noLayout: true,
    },
    component: () => import("@/views/Site/SiteHoSignCompleteView.vue"),
  },
];

export { openRoutes };
