import { AuthConnection, toQueries, getIds } from "@/api/config.js";

const defaultAlert = true;

/** 유저관리 */
export function getUsers(params) {
  return AuthConnection().get(`/sys/users?${toQueries(params)}`);
}
export function getSearchUsers(params) {
  return AuthConnection().get(`/sys/users/search?${toQueries(params)}`);
}
export function getAuthUsers(params) {
  return AuthConnection().get(`/sys/users/auth?${toQueries(params)}`);
}
export function postUserList(data) {
  return AuthConnection(defaultAlert).post(`/sys/users/list`, data);
}

export function postUserSiteMap(id, data) {
  return AuthConnection(defaultAlert).post(`/sys/userSiteMap/${id}`, data);
}

/** 법인관리 */
export function getCorporations(params) {
  return AuthConnection().get(`/sys/corporations?${toQueries(params)}`);
}

/** 메뉴관리 */
export function getMenus(params) {
  return AuthConnection().get(`/sys/menus?${toQueries(params)}`);
}
export function getMenuPage(params) {
  return AuthConnection().get(`/sys/menus/page?${toQueries(params)}`);
}
export function postMenus(data) {
  return AuthConnection().post(`/sys/menus`, data);
}
export function postMenusSub(id, data) {
  return AuthConnection().post(`/sys/menus/${id}/sub`, data);
}
export function putMenus(id, data) {
  return AuthConnection().put(`/sys/menus/${id}`, data);
}
export function deleteMenu(id) {
  return AuthConnection().delete(`/sys/menus/${id}`);
}

export function postMenuList(data) {
  return AuthConnection().post(`/sys/menus/list`, data);
}

export function postMenuSubList(id, data) {
  return AuthConnection().post(`/sys/menus/${id}/sub/list`, data);
}

export function deleteMenuList(data) {
  return AuthConnection().delete(`/sys/menus/list?ids=${getIds(data)}`);
}

/** 권한그룹 APIs */
export function getAuthorities(params) {
  return AuthConnection().get(`/sys/authorities?${toQueries(params)}`);
}
export function postAuthorityList(data) {
  return AuthConnection(defaultAlert).post(`/sys/authorities/list`, data);
}
export function deleteAuthorityList(data) {
  return AuthConnection(defaultAlert).delete(
    `/sys/authorities/list?ids=${getIds(data)}`
  );
}
export function getAuthorityUsers(id) {
  return AuthConnection().get(`/sys/authorities/${id}/users`);
}
export function postAuthorityUsers(id, data) {
  return AuthConnection(defaultAlert).post(
    `/sys/authorities/${id}/users`,
    data
  );
}
export function deleteAuthorityUserList(id, data) {
  return AuthConnection(defaultAlert).delete(
    `/sys/authorities/${id}/users?userIds=${getIds(data)}`
  );
}

/** 권한-메뉴 관리 APIs */
export function getAuthorityMenus(id) {
  return AuthConnection().get(`/sys/authorities/${id}/menus`);
}
export function postAuthorityMenus(id, data) {
  return AuthConnection(defaultAlert).post(
    `/sys/authorities/${id}/menus`,
    data
  );
}
export function deleteAuthorityMenuList(id, data) {
  return AuthConnection().delete(
    `/sys/authorities/${id}/menus?menuIds=${data}`
  );
}

/** 리소스 관리 APIs */
export function getResources(params) {
  return AuthConnection(defaultAlert).get(
    `/sys/resources?${toQueries(params)}`
  );
}
export function postResources(data) {
  return AuthConnection(defaultAlert).post(`/sys/resources/list`, data);
}
export function deleteResourceList(data) {
  return AuthConnection(defaultAlert).delete(
    `/sys/resources/list?ids=${getIds(data)}`
  );
}

/** 권한-리소스 관리 APIs */
export function getAuthorityResources(id) {
  return AuthConnection().get(`/sys/authorities/${id}/resources`);
}
export function postAuthorityResources(id, data) {
  return AuthConnection(defaultAlert).post(
    `/sys/authorities/${id}/resources`,
    data
  );
}
export function deleteAuthorityResourceList(id, data) {
  return AuthConnection().delete(
    `/sys/authorities/${id}/resources?resourceIds=${data}`
  );
}

/** 사용이력 조회 */
export function getActivities(params) {
  return AuthConnection().get(`/sys/activities?${toQueries(params)}`);
}

/** 사용이력 저장 */
export async function postActivity(data) {
  return AuthConnection().post(`/sys/activity`, data);
}
