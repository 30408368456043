<template>
  <v-dialog
    :max-width="widthByTextLength"
    v-model="temp"
    style="z-index: 100"
    v-if="openConfirm"
  >
    <v-card class="ConfirmBox">
      <p class="cf-title" style="font-weight: bold">확인</p>
      <p v-show="text" v-html="text" class="cf-text"></p>
      <div class="cf-btn">
        <span class="can" @click="no()" v-show="!hideNo">취소</span>
        <span class="ok" @click="yes()">확인</span>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      temp: false,
    };
  },
  computed: {
    confirm() {
      return this.$store.getters.getConfirm;
    },
    text() {
      return this.confirm?.text || "";
    },
    openConfirm() {
      return this.$store.getters.getOpenConfirm;
    },
    hideNo() {
      return this.confirm?.hideNo || false;
    },
    widthByTextLength() {
      if (this.text && this.text.length > 30) {
        return 450;
      }
      return 300;
    },
  },
  watch: {
    temp(val) {
      if (!val) {
        this.close();
      }
    },
    openConfirm(val) {
      if (val) {
        this.temp = true;
      }
    },
  },
  methods: {
    close() {
      this.temp = false;
      this.$store.commit("SET_CONFIRM", null);
    },
    no() {
      if (this.confirm.no) {
        this.confirm.no();
      }
      this.close();
    },
    yes() {
      if (this.confirm.yes) {
        this.confirm.yes();
      }
      this.close();
    },
  },

  created() {
    this.close();
  },
};
</script>

<style scoped>
.action {
  padding-top: 10px;
}
</style>
