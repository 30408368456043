import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
import menu from "./menu";
import common from "./common";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    menu,
    common,
  },
  plugins: [
    /** createPersistedState: 새로고침해도 데이터를 유지 시키는 Modules */
    createPersistedState({
      paths: ["auth", "common"],
    }),
  ],
});

export default store;
