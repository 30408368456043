var systemRoutes = [
  {
    path: "/system/menus",
    name: "MenuManagement",
    component: () => import("@/views/System/MenuManagementView"),
  },
  {
    path: "/system/user/authorities",
    name: "AuthorityManagement",
    component: () => import("@/views/System/AuthorityManagementView"),
  },
  {
    path: "/system/menu/authorities",
    name: "AuthorityMenuManagement",
    component: () => import("@/views/System/AuthorityMenuManagementView"),
  },
  {
    path: "/system/commcodes",
    name: "CommonCodeManagement",
    component: () => import("@/views/System/CommonCodeManagementView"),
  },
  {
    path: "/system/resources",
    name: "ResourceManagement",
    component: () => import("@/views/System/ResourceManagementView"),
  },
  {
    path: "/system/resource/authorities",
    name: "AuthorityResourceManagement",
    component: () => import("@/views/System/AuthorityResourceManagementView"),
  },
  {
    path: "/system/users",
    name: "UserManagement",
    component: () => import("@/views/System/UserManagementView"),
  },
  {
    path: "/system/activities",
    name: "Activity",
    component: () => import("@/views/System/ActivityView"),
  },
];

export { systemRoutes };
