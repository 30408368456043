var defectRoutes = [
  // BS
  {
    path: "/defects/bs/detail",
    name: "DefectCreateBsView",
    component: () => import("@/views/Defect/DefectView"),
  },
  {
    path: "/defects/bs/detail/:id",
    name: "DefectBsView",
    component: () => import("@/views/Defect/DefectView"),
  },
  {
    path: "/defects/bs/list",
    name: "DefectBsListView",
    component: () => import("@/views/Defect/DefectListView"),
  },
  {
    path: "/defect-works/bs/list",
    name: "DefectWorkBsListView",
    component: () => import("@/views/DefectWork/DefectWorkListView"),
  },
  {
    path: "/defects/bs/status",
    name: "DefectStatusBsView",
    component: () => import("@/views/Defect/DefectStatusView"),
  },

  // 품질점검
  {
    path: "/defects/quality/detail",
    name: "DefectCreateQualityView",
    component: () => import("@/views/Defect/DefectView"),
  },
  {
    path: "/defects/quality/detail/:id",
    name: "DefectQualityView",
    component: () => import("@/views/Defect/DefectView"),
  },
  {
    path: "/defects/quality/list",
    name: "DefectQualityListView",
    component: () => import("@/views/Defect/DefectListView"),
  },
  {
    path: "/defect-works/quality/list",
    name: "DefectWorkQualityListView",
    component: () => import("@/views/DefectWork/DefectWorkListView"),
  },
  {
    path: "/defects/quality/status",
    name: "DefectStatusQualityView",
    component: () => import("@/views/Defect/DefectStatusView"),
  },

  // 사전점검
  {
    path: "/defects/pre/detail",
    name: "DefectCreatePreView",
    component: () => import("@/views/Defect/DefectView"),
  },
  {
    path: "/defects/pre/detail/:id",
    name: "DefectPreView",
    component: () => import("@/views/Defect/DefectView"),
  },
  {
    path: "/defects/pre/list",
    name: "DefectPreListView",
    component: () => import("@/views/Defect/DefectListView"),
  },
  {
    path: "/defect-works/pre/list",
    name: "DefectWorkPreListView",
    component: () => import("@/views/DefectWork/DefectWorkListView"),
  },
  {
    path: "/defects/pre/status",
    name: "DefectStatusPreView",
    component: () => import("@/views/Defect/DefectStatusView"),
  },

  // 입주점검
  {
    path: "/defects/move_in/detail",
    name: "DefectCreateMoveinView",
    component: () => import("@/views/Defect/DefectView"),
  },
  {
    path: "/defects/move_in/detail/:id",
    name: "DefectMoveinView",
    component: () => import("@/views/Defect/DefectView"),
  },
  {
    path: "/defects/move_in/list",
    name: "DefectMoveinListView",
    component: () => import("@/views/Defect/DefectListView"),
  },
  {
    path: "/defect-works/move_in/list",
    name: "DefectWorkMoveinListView",
    component: () => import("@/views/DefectWork/DefectWorkListView"),
  },

  // 사후하자
  {
    path: "/defects/detail",
    name: "DefectCreateView",
    component: () => import("@/views/Defect/DefectView"),
  },

  {
    path: "/defects/detail/:id",
    name: "DefectView",
    component: () => import("@/views/Defect/DefectView"),
  },
  {
    path: "/defects/list",
    name: "DefectListView",
    component: () => import("@/views/Defect/DefectListView"),
  },
  {
    path: "/defect-works/list",
    name: "DefectWorkListView",
    component: () => import("@/views/DefectWork/DefectWorkListView"),
  },
];

defectRoutes = defectRoutes.map((route) => {
  route.meta = {
    siteDependent: true,
  };
  return route;
});

export { defectRoutes };
