<template>
  <v-btn
    :class="{
      'common-button': true,
      'export-button': !small && !functional,
      'small-button': small,
      'function-button': functional,
      disabled: disabled || checkDisabled,
    }"
    @click="$emit('click')"
    outlined
    elevation="0"
    :disabled="disabled || checkDisabled"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  props: {
    text: String,
    small: Boolean,
    functional: Boolean,
    disabled: Boolean,
  },
  computed: {
    checkDisabled() {
      let method = {
        추가: "POST",
        저장: "PUT",
        삭제: "DELETE",
      }[this.text];
      if (!method) {
        return false;
      }
      return !this.$funcs.checkAction(this.$route.name, method);
    },
  },
};
</script>

<style scoped>
.common-button {
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-weight: 600 !important;
  height: 36px !important;
  letter-spacing: 0;
  font-size:12px;
  min-width:52px !important;
}

.export-button {
  color: white;
  background-color: rgba(0, 68, 132, 0.8);
  border-color: rgba(0, 68, 132, 0.8);
}
.small-button {
  font-size: 12px !important;
  min-width: 40px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-weight: 450 !important;
  line-height: 18px !important;
  height: 20px !important;
  color: white;
  background-color: rgba(0, 68, 132, 0.8) !important;
  border-color: rgba(0, 68, 132, 0.8) !important;
}
.function-button {
  background-color: transparent !important;
  border-color: rgba(0, 68, 132, 0.8);
  color: rgba(0, 68, 132, 0.8);
}
.disabled {
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}

.btnSmall .common-button {
  height:30px !important;
}
</style>
