// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "AIzaSyA4xhxng1zIJVShX_ltCgM9aTNAkWeidaI",
  // authDomain: "wmcsm-fcm.firebaseapp.com",
  // projectId: "wmcsm-fcm",
  // storageBucket: "wmcsm-fcm.appspot.com",
  // messagingSenderId: "318792932337",
  // appId: "1:318792932337:web:bd08d1c40ea700088a8250",
  // measurementId: "G-GQWYL2MKN2",

  // Firebase 정보 변경 (파도시스템계정)
  apiKey: "AIzaSyApGOY2NmPOMlutaWJQcZWlvCVo6dU417g",
  authDomain: "woomi-csm.firebaseapp.com",
  projectId: "woomi-csm",
  storageBucket: "woomi-csm.appspot.com",
  messagingSenderId: "11810987044",
  appId: "1:11810987044:web:f5fe5a49b3fdc610e78cb1",
};

// Initialize Firebase
let messaging = null;
try {
  const app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
} catch (e) {
  console.log(e);
}

export default messaging;
