<template>
  <v-app id="app">
    <Layout v-if="isLayout && !isFlutter" />
    <router-view v-else />
    <GlobalComponent />
    <Forbidden v-if="userForbidden" @logOut="userForbiddenLogOut()" />
  </v-app>
</template>

<script>
import "@/assets/overrides.sass";
import Layout from "@/components/Layout/Layout.vue";
import GlobalComponent from "@/components/Global/GlobalComponent.vue";
import Forbidden from "@/components/Global/Forbidden.vue";

export default {
  name: "App",
  components: { Layout, GlobalComponent, Forbidden },
  computed: {
    isLayout() {
      return this.$store.getters.isLayout;
    },
    user() {
      return this.$store.getters.getUser;
    },
    isFlutter() {
      let is = false; //false true
      let browserInfo = navigator.userAgent.toLowerCase();
      if (browserInfo.indexOf("wikey_flutter") > 0) {
        is = true;
      }
      return is;
    },
  },
  data: () => ({
    registration: null,
    refreshing: false,
    isLoaded: false,
    userForbidden: false, // 유저 최초 로그인 및 권한 없을 경우 사용 제한.
  }),
  watch: {
    $route(to, from) {
      //console.log(to, from);
      if (from.name == "Login") {
        console.log("watch route dashboardView");
        this.refresh();
        this.checkFirstLogin();
      } else if (to.name == "DashboardView") {
        console.log("watch route dashboardView");
        this.checkFirstLogin();
        this.refresh();
      } else if (!this.isLoaded) {
        if (!to?.meta?.openURL) {
          this.isLoaded = true;
          this.sourceRefresh();
          this.refresh();
        }
      }
    },
  },
  methods: {
    refresh() {
      console.log("refresh");
      // 로그인 페이지에서는 미사용
      if (this.$route.name === "Login") {
        return;
      } else if (this.$route.name === "MobileLogout") {
        //하자앱 로그아웃 화면에서도 미사용!!
        return;
      } else if (!this.user?.id) {
        // 로그인 정보 사라졌을 시 로그아웃
        this.$store.dispatch("logOut");
        return;
      }

      // 유저정보 업데이트
      this.$store.dispatch("refreshUser");
      this.$store.dispatch("refreshEnums");

      this.accessCheck();
    },
    accessCheck() {
      console.log("accessCheck");
      // (TODO)권한 관련 개선 필요.
      // 현재 기준, 권한 관련 로직이 대부분 authorities[0]으로 구현되어있으므로..
      if (this.user.authorities[0].id == 12) {
        // 작업자 권한인 경우
        alert(
          "관리자가 아닙니다. 작업자 사이트로 로그인해주세요. (worker.smartlynn.co.kr)"
        );
        this.$store.dispatch("logOut");
        return;
      }

      // 유저 접근권한 체크 및 만료 시, 로그아웃.
      if (["MANAGER", "WORKER"].includes(this.user.type)) {
        this.$store.dispatch("checkAccess", this.user).then((access) => {
          if (!access[0].canAccess) {
            this.$store.dispatch("logOut");
          }
        });
      }
    },

    // Source Refresh
    sourceRefresh() {
      console.log("sourceRefresh");
      if (this.isFlutter) {
        return;
      }
      try {
        document.addEventListener(
          "serviceWorkerUpdateEvent",
          this.appUpdateUI,
          {
            once: true,
          }
        );
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          if (this.refreshing) return;
          this.refreshing = true;
          window.location.reload();
        });
      } catch (e) {
        console.log("sourceRefresh", e);
      }
    },
    appUpdateUI: function (e) {
      this.registration = e.detail;
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    },
    checkFirstLogin() {
      console.log("checkFirstLogin");
      if (this.user.authorities && !this.user.authorities.length) {
        this.userForbidden = true;
      }

      // SSO 인증 관련 탭 닫기
      if (window.name == "wmnets") {
        window.open("", "_self");
        window.close();
      }
    },
    userForbiddenLogOut() {
      console.log("userForbiddenLogOut");
      this.userForbidden = false;
      this.$store.dispatch("logOut");
    },
  },
  created() {
    if (this.$route.name == "DashboardView") {
      console.log("created() dashboardView");
      this.checkFirstLogin();
    }
  },
};
</script>

<style type="text/css"></style>
