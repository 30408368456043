import store from "@/store/index";

/** format raw timestamp to text */
export function toTimeformat(raw) {
  if (!raw || raw == "null") return "";
  let date = raw.slice(0, 10);
  let time = raw.slice(11, 16);
  return `${date} ${time}`;
}

export function toDateOnlyformat(raw) {
  if (!raw) return "";
  let date = raw.slice(0, 10);
  return `${date}`;
}

/** excel date to formatted date text */
export function toDateformat(raw) {
  if (!raw) return "";

  let year, month, day;

  if (raw.includes("/")) {
    [month, day, year] = raw.split("/");
    year = "20" + year;
  } else {
    [year, month, day] = raw.split("-");
  }

  day = day.padStart(2, "0");
  month = month.padStart(2, "0");
  return `${year}-${month}-${day}`;
}

/** return now full string ex) 20220101 */
export function getNowFullString(dash = false) {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  var hours = String(today.getHours()).padStart(2, "0");
  var minues = String(today.getMinutes()).padStart(2, "0");
  var seconds = String(today.getSeconds()).padStart(2, "0");

  if (dash) {
    return `${yyyy}-${mm}-${dd}T${hours}:${minues}:${seconds}`;
  } else {
    return `${yyyy}${mm}${dd}${hours}${minues}`;
  }
}

export function getFullString(time, dash = false) {
  var dd = String(time.getDate()).padStart(2, "0");
  var mm = String(time.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = time.getFullYear();
  var hours = String(time.getHours()).padStart(2, "0");
  var minues = String(time.getMinutes()).padStart(2, "0");
  var seconds = String(time.getSeconds()).padStart(2, "0");

  if (dash) {
    return `${yyyy}-${mm}-${dd}T${hours}:${minues}:${seconds}`;
  } else {
    return `${yyyy}${mm}${dd}${hours}${minues}${seconds}`;
  }
}

function dateToString(date, dash = true) {
  var dd = String(date.getDate()).padStart(2, "0");
  var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = date.getFullYear();

  if (dash) {
    return `${yyyy}-${mm}-${dd}`;
  } else {
    return `${yyyy}${mm}${dd}`;
  }
}

/** return now string ex) 20220101 */
export function getNowString(dash) {
  var today = new Date();
  return dateToString(today, dash);
}

export function getAddedFromNowString(days = 0) {
  var date = new Date();
  date.setDate(date.getDate() + days);
  return dateToString(date, true);
}

export function getNowTime(type) {
  if (type == "hour") {
    return new Date().getHours();
  } else if (type == "minute") {
    return new Date().getMinutes();
  }
}

export function getDateString(date, dash = true) {
  /** 현재 날짜에 n일 뒤 */
  var d1 = new Date();
  var d2 = new Date(d1);

  d2.setMinutes(d1.getMinutes() + 60 * 9);
  d2.setDate(d2.getDate() + date);

  let [year_today, month_today, day_today] = d2
    .toISOString()
    .substr(0, 10)
    .split("-");

  if (dash) {
    return `${year_today}-${month_today}-${day_today}`;
  } else {
    return `${year_today}${month_today}${day_today}`;
  }
}

export function toExcelDate(val) {
  if (!val) return "";
  let temp = formatDate(val);
  return temp.val.replaceAll("-", ".");
}

export function copy(val) {
  try {
    return JSON.parse(JSON.stringify(val));
  } catch {
    return null;
  }
}

export function comma(val) {
  if (val == 0) return 0;
  if (val != "" && val != null && val != undefined) {
    val = Number(val);
    return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "0";
}

/** Enums */

/** type에 해당하는 enum을 배열로 리턴 */
export function getEnumList(name, options = []) {
  var allEnums = store.getters.getEnums;
  var enums = allEnums[name];
  if (options.length) {
    enums = enums.filter((e) => options.includes(e.type));
  }
  return enums;
}

/** type과 code에 해당하는 tilte 리턴 */
export function getTitle(type, code) {
  if (store.getters.getEnumCodeMaps[type]) {
    return store.getters.getEnumCodeMaps[type][code];
  } else {
    return null;
  }
}

/** type과 title에 해당하는 code 리턴 */
export function getCode(type, title) {
  if (store.getters.getEnumTitleMaps[type]) {
    return store.getters.getEnumTitleMaps[type][title];
  } else {
    return null;
  }
}

/** 사용자 사용가능 API 체크 for UI */
export function checkAction() {
  // export function checkAction(name, method) {
  // const actions = store.getters.getActions;
  // return actions[name]?.[method]?.length > 0;

  // TODO: 권한 관련 완료 후 위 로직 적용.
  return true;
}

/** 개인정보 마스킹 */
export function maskingName(name) {
  // 이름 마스킹 : 홍길동 -> 홍*동
  if (!name) return "";

  name = String(name);
  name = name[0] + "*" + name[name.length - 1];

  return name;
}

export function maskingMobile(mobile) {
  // 중간 넷자리 마스킹 : 01012345678 -> 010****5678
  if (!mobile) return "";

  mobile = String(mobile);
  mobile = mobile.slice(0, 3) + "****" + mobile.slice(7, 11);

  return mobile;
}

export function formatMobile(mobile) {
  // formatting : 01012345678 -> 010-1234-5678
  if (!mobile) return "";
  if (mobile.length != 11) return mobile;

  mobile = String(mobile);
  mobile =
    mobile.slice(0, 3) + "-" + mobile.slice(3, 7) + "-" + mobile.slice(7, 11);

  return mobile;
}

export function maskingEmail(email) {
  // 앞 두자레 제외 마스킹 : abcdefg@wm.co.kr -> ab*****@wm.co.kr
  if (!email) return "";
  if (!email.includes("@")) return email;

  email = String(email);
  let temp = email.split("@");

  if (temp[0].length <= 2) {
    email = "****";
  } else {
    email = temp[0].slice(0, 2) + "*".repeat(temp[0].length - 2);
  }

  return email + "@" + temp[1];
}

export function parseBy(by) {
  let val;
  if (!by || !by.includes(":")) {
    val = by;
  } else {
    val = by.split(":")[0];
  }

  if (!val || val == "null") {
    return "-";
  }
  return val;
}

export function isMobileDevice() {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i) ||
    navigator.userAgent.toLowerCase().match(/wikey_flutter/i)
  );
}

export function round(value, decimal = 2) {
  return Number(value).toFixed(decimal);
}

export function orderBy(items, key = "") {
  function numberStringFn(a, b) {
    if (key) {
      try {
        a = Number(a[key]);
        b = Number(b[key]);
      } catch {
        a = 0;
        b = 0;
      }
    }

    if (Number(a) < Number(b)) {
      return -1;
    } else {
      return 1;
    }
  }

  return items.sort(numberStringFn);
}

export function orderByString(items, key = "") {
  function numberStringFn(a, b) {
    return a[key].localeCompare(b[key]);
  }

  return items.sort(numberStringFn);
}

function validateDate(val) {
  if (!val || val.length != 10) return true;
  if (val.length > 10) return false;

  let date = new Date(val);
  if (date == "Invalid Date") {
    return false;
  }

  let dateString = getFullString(date, true).slice(0, 10);

  return dateString == val;
}

export function formatDate(val, remove = true) {
  let temp = val.replace(/\D/g, "");
  let isValid = false;

  if (temp.length >= 8) {
    temp = temp.slice(0, 8);
    val = `${temp.slice(0, 4)}-${temp.slice(4, 6)}-${temp.slice(6, 8)}`;
    isValid = validateDate(val);
  } else {
    if (remove) {
      val = "";
    }
  }
  return { val, isValid };
}

function validateTime(val) {
  if (!val || val.length != 5) return true;
  if (val.length > 5) return false;

  let time = new Date("2000-01-01 " + val);
  if (time == "Invalid Date") {
    return false;
  }

  let dateString = getFullString(time, true).slice(11, 16);

  return dateString == val;
}

export function formatTime(val) {
  let temp = val.replace(/\D/g, "");
  let isValid = false;

  if (temp.length >= 4) {
    temp = temp.slice(0, 4);
    val = `${temp.slice(0, 2)}:${temp.slice(2, 4)}`;
    isValid = validateTime(val);
  }
  return { val, isValid };
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

/**
 * getCurrentType()
 * - timetable 계산하여 현재 진행중인 점검타입을 리턴
 * - 시작일자만으로 판단하여 공백기간은 이전 타입으로 리턴한다. (#7554 참고)
 * - 사전점검의 경우 시작~종료일이 3일 정도로 입력되므로 시작일자로 판단한다
 *   + (2025-01-15) 2년차 이후 다시 '사후하자'로 등록되어야함 (#8534)
 * */
export function getCurrentType(timeTables) {
  if (!timeTables) {
    return "";
  }

  const days = 1000 * 60 * 60 * 24; // 하루
  const now = new Date().getTime();

  /* AS 이후 분류(2년차,3년차..)는 종료일이 지난 경우 제외한다
     2년차 접수 기간 끝난 후 사후관리 접수
     따라서 사후 이후 단계가 종료일이 닫혔다면 'AFTER'로 선택되게 유도 */
  let asType = getEnumList("InspectionType").filter((types) => types.type == 'AS' && types.code != 'AFTER').map((i)=>i.code);

  // 날짜 차이가 0보다 큰 것들 & 날짜 차이 array에 저장
  let filterList = timeTables.filter((x) => {
    const startDate = new Date(x.startDate).getTime();
    const endDate = new Date(x.endDate).getTime();
    let asEndDateCheck = endDate && endDate < now && asType.includes(x.type); // 종료일이 입력되어 있고, 종료일이 지남 (AS)
    //console.log(x.type, asEndDateCheck);
    let diff = (now - startDate) * days;
    x.diff = diff;
    return diff >= 0 && x.type != "MOVE_IN_PERIOD" && x.startDate != null && !asEndDateCheck;
  });

  // 날짜차이가 가장 적은 현장
  let min = Math.min(...filterList.map((item) => item.diff));
  let result = filterList.filter((item) => item.diff == min);

  if (result.length > 0) {
    return result[0].type;
  }

  return "";
}

// 이미지파일 크기 줄이는 함수 (callback 방식)
export function downSize(file, callback) {
  // FileReader 객체 생성
  const reader = new FileReader();

  // 파일 로드 완료 시 호출되는 콜백 함수
  reader.onload = function (e) {
    const img = new Image();
    img.src = e.target.result;

    // 이미지 다운사이징
    const maxWidth = 1280; // 원하는 최대 너비
    const maxHeight = 1280; // 원하는 최대 높이
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    img.onload = function () {
      let newWidth = img.width;
      let newHeight = img.height;

      if (img.width > maxWidth || img.height > maxHeight) {
        const aspectRatio = img.width / img.height;
        if (img.width > maxWidth) {
          newWidth = maxWidth;
          newHeight = maxWidth / aspectRatio;
        }
        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = maxHeight * aspectRatio;
        }
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      // 리사이즈된 이미지를 Blob으로 변환
      canvas.toBlob(function (blob) {
        // Blob을 File 객체로 변환 (이미지 파일 이름은 변경 가능)
        const resizedFile = new File([blob], file.name, {
          type: file.type,
        });

        // 리사이즈된 파일 객체를 사용할 수 있음
        callback(resizedFile);
      }, file.type);
    };
  };

  // 파일을 읽기 시작
  reader.readAsDataURL(file);
}

// 이미지파일 크기 줄이는 함수 (return 방식)
export async function getDownSizeFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();

    // 파일 로드 완료 시 호출되는 콜백 함수
    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;

      // 이미지 다운사이징
      const maxWidth = 1280; // 원하는 최대 너비
      const maxHeight = 1280; // 원하는 최대 높이
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      img.onload = function () {
        let newWidth = img.width;
        let newHeight = img.height;

        if (img.width > maxWidth || img.height > maxHeight) {
          const aspectRatio = img.width / img.height;
          if (img.width > maxWidth) {
            newWidth = maxWidth;
            newHeight = maxWidth / aspectRatio;
          }
          if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = maxHeight * aspectRatio;
          }
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // 리사이즈된 이미지를 Blob으로 변환
        canvas.toBlob(function (blob) {
          // Blob을 File 객체로 변환 (이미지 파일 이름은 변경 가능)
          const resizedFile = new File([blob], file.name, {
            type: file.type,
          });

          // 리사이즈된 파일 객체를 사용할 수 있음
          resolve(resizedFile);
        }, file.type);
      };
    };

    // 파일을 읽기 시작
    reader.readAsDataURL(file);
  });
}

export function getDatesBy(start, end) {
  const dateArray = [];
  let startDate = new Date(start);
  let endDate = new Date(end);

  while (startDate <= endDate) {
    dateArray.push(startDate.toISOString().split("T")[0]);
    startDate.setDate(startDate.getDate() + 1);
  }
  return dateArray;
}
