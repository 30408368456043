import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import firebaseMessaging from "@/firebase";

import "@/plugins/global-components";
import vuetify from "@/plugins/vuetify";

import * as funcs from "@/utils/common.js";

import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
//import { appendToCognitoUserAgent } from "amazon-cognito-identity-js";
import VueSignaturePad from "vue-signature-pad";

Vue.prototype.$messaging = firebaseMessaging;
Vue.config.productionTip = false;

LicenseManager.setLicenseKey(process.env.VUE_APP_AG_GRID_KEY);

/**
 * Overall 하게 사용되는 함수 정의.
 * 정의: utils/common.js
 * 사용: this.$funcs.copy(value);
 **/
Vue.prototype.$funcs = funcs;

import "./registerServiceWorker";

Vue.use(VueSignaturePad);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

console.log("WMCSM V0.0.9");
