<template>
  <!-- temporary
absolute
app -->
  <v-navigation-drawer
    :class="[{ on: toggleMenu === true }, 'elevation5']"
    width="300"
    :temporary="isSm"
    :permanent="!isSm"
    v-model="drawer"
    app
    color="rgba(0,68,132,0.9)"
    style="z-index: 99"
  >
    <span class="navi-in" @click="onMenuToggle()"
      ><img src="@/assets/images/icons/leftarrow_w.png"
    /></span>
    <v-list-item @click="goPage({ id: 0, url: '/' })" class="ma-1" dark>
      <v-list-item-content align="left" style="padding: 20px 0">
        <v-img
          src="@/assets/images/common/logo_w.png"
          max-width="80px"
          class="mb-0"
        />
        <v-list-item-title
          class="text-h6 font-weight-bold"
          style="color: #fff; margin-top: 10px; height: 20px; line-height: 1"
        >
          통합 고객지원 시스템
        </v-list-item-title>
        <!-- <v-list-item-subtitle>0.0.1 </v-list-item-subtitle> -->
      </v-list-item-content>
    </v-list-item>

    <v-list dense class="leftmenu-list" dark>
      <v-list-group
        v-for="item in allMenus"
        :key="item.id"
        v-model="item.active"
        no-action
        class="leftmenu-group leftmenu-inactive active"
        active-class="leftmenu-active"
        dark
        color="#fff"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="item-title">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item-group v-model="selectedItem" class="mt-1">
          <v-list-item
            v-for="child in item.children"
            :key="child.id"
            link
            class="leftmenu-inactive"
            active-class="leftmenu-active"
            @click="goPage(child)"
            @mouseover="$funcs.isMobileDevice() ? null : (hoverId = child.id)"
            @mouseleave="$funcs.isMobileDevice() ? null : (hoverId = null)"
            :value="child.id"
            dark
          >
            <v-list-item-icon>
              <v-icon>mdi-circle-small</v-icon>
            </v-list-item-icon>

            <v-list-item-content style="margin-left: -15px">
              <v-list-item-title class="item-title">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon
              v-show="child.id == hoverId"
              @click.stop="setFav(child)"
            >
              <v-icon color="#ffca29" v-if="isFavorite(child.id)"
                >mdi-star</v-icon
              >
              <v-icon v-else>mdi-star-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import * as system from "@/api/system";

export default {
  name: "LeftMenu",
  props: ["open", "isSm"],
  data() {
    return {
      toggleMenu: false,

      drawer: false,
      right: null,

      selectedItem: null,
      activeCategory: "", //현재 열려있는 메뉴

      hoverId: null,
    };
  },
  computed: {
    ...mapGetters({
      menus: "getMenus",
      user: "getUser",
      access: "getAccess",
      favorites: "getFavorites",
    }),
    allMenus() {
      let items = [];
      if (this.favorites.length) {
        items.push({
          id: 0,
          active: false,
          title: "즐겨찾기",
          children: this.favorites,
        });
      }
      items = items.concat(this.menus);
      return items;
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.drawer = true;
      }
    },
    drawer(val) {
      if (!val) {
        this.menuClose(val);
      }
    },
  },
  methods: {
    ...mapMutations({
      setMenus: "SET_MENUS",
      setPages: "SET_PAGES",
      setFavorites: "SER_FAVORITES",
    }),
    onMenuToggle() {
      this.toggleMenu = !this.toggleMenu;
      this.$emit("toggleBox");
    },
    menuClose() {
      this.$emit("SetmenuClose");
    },
    goPage(item) {
      if (item.name == "DefectView") {
        let browserInfo = navigator.userAgent.toLowerCase();
        //플루터 로그인인 경우,
        if (browserInfo.indexOf("wikey_flutter") > 0) {
          let type = this.$funcs
            .getEnumList("InspectionType")
            .find((type) => item.url.includes(type.code.toLowerCase()));

          let typeCode = "";
          if (!type) {
            typeCode = "AFTER";
          } else {
            typeCode = type.code;
          }

          window.flutter_inappwebview
            .callHandler("gotoDefectRegist", typeCode)
            .then(function (result) {
              console.log("@@@ gotoDefectRegist  result: ", result);
            });

          this.drawer = false;
          return;
        }
      }

      this.setActiveMenu(item.id);
      this.$router.push({ path: item.url });
    },
    setActiveMenu(id) {
      if (this.activeCategory == id) {
        this.activeCategory = "";
      } else {
        this.activeCategory = id;
      }
    },

    // APIs
    getAuthorityMenuAPI(id) {
      return new Promise((resolve, reject) => {
        system
          .getAuthorityMenus(id)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // Utils
    setAuthMenus(authMenus, allMenus) {
      const authMenusIdMaps = authMenus.map((menu) => menu.id);

      let menus = [];

      allMenus.forEach((menu) => {
        let children = [];
        menu.children.forEach((child) => {
          if (child.url == this.$route.path) {
            this.selectedItem = child.id;
            menu.active = true;
          }

          if (authMenusIdMaps.includes(child.id) && child.menuExpose) {
            children.push(child);
          }
        });
        menu.children = children;
        if (children.length) {
          menus.push(menu);
        }
      });
      return menus;
    },
    setManagerMenus(allAuthMenu) {
      let enums = {
        QUALITY: 97,
        PRE: 98,
        MOVE_IN: 136,
        AFTER: 96,
        BS: 94,
      };
      //BS관리 -94

      console.log("allAuthMenu", allAuthMenu);

      let accessAbleMenu = this.access.items.map((item) => {
        return enums[item.type] ? enums[item.type] : 0;
      });

      console.log("accessAbleMenu", accessAbleMenu);

      allAuthMenu = allAuthMenu.filter((menu) => {
        if ([96, 97, 98, 94].includes(menu.id)) {
          return accessAbleMenu.includes(menu.id);
        } else if (menu.id == 136) {
          // todo : 서버에서 권한,기간에 맞는 메뉴를 내려주는 방식으로 변경
          // 입주점검 관리 메뉴의 경우, 하자 외에 '입주총괄관리'가 노출되어야하므로
          // accessAbleMenu를 검사하여 자식 메뉴를 삭제한다. (2024.03)  -'입주총괄관리'는 모든 매니저에게 보여야함!!
          if (accessAbleMenu.includes(menu.id)) {
            // 입주점검으로 지정된 경우 모든 메뉴
            return true;
          } else if (accessAbleMenu.includes(96)) {
            menu.children = menu.children.filter((x) => x.id == 174);
            return menu.children?.length ? true : false;
          }
        } else {
          return true;
        }
      });
      console.log(allAuthMenu);
      return allAuthMenu;
    },
    isFavorite(id) {
      return this.favorites.some((item) => item.id == id);
    },
    setFav(item) {
      let temp = this.$funcs.copy(this.favorites);

      let fav = temp.findIndex((v) => v.id == item.id);

      if (fav > -1) {
        temp.splice(fav, 1);
      } else {
        temp.push(item);
      }

      this.setFavorites(temp);
    },
  },
  created() {
    console.log(window);
    this.selectedItem = 0;

    system
      .getMenus({ sort: "displayOrder", size: 1000 })
      .then((res) => {
        if (
          this.user &&
          this.user.authorities &&
          this.user.authorities.length
        ) {
          this.getAuthorityMenuAPI(this.user.authorities[0].id).then(
            (authMenus) => {
              let allAuthMenu = this.setAuthMenus(authMenus, res.data.content);
              console.log(allAuthMenu);
              if (this.user.type == "MANAGER") {
                allAuthMenu = this.setManagerMenus(allAuthMenu);
              }
              this.setMenus(allAuthMenu);
            }
          );
        }
      })
      .catch(() => {
        // 권한 오류로 불러오지 못할 경우 메뉴 삭제
        this.setMenus([]);
      });

    system.getMenuPage({ size: 1000 }).then((res) => {
      this.setPages(res.data.content);
    });
  },
};
</script>
<style scoped>
.leftmenu-list {
  margin-top: -10px;
  margin-right: 5px;
  padding-right: 7.5px;
  padding-left: 7.5px;
  padding-bottom: 90px; /* 아이폰 영역 대응용 padding */
  border-radius: 5px;
}
.leftmenu-active {
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.leftmenu-inactive {
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.v-list-item {
  padding: 0 10px !important;
}

.v-list-item__icon {
  margin-right: 16px !important;
  padding-left: 5px;
}
.v-list-item__icon:last-of-type {
  margin-right: 5px !important;
  padding-left: 5px;
}

.leftmenu-group {
  border-radius: 5px;
  margin-left: 5px;
}

.item-title {
  font-size: 15px !important;
}
.elevation5 {
  box-shadow: 0 1px 10px -1px rgba(85, 85, 85, 0.08),
    0 2px 30px 0 rgba(85, 85, 85, 0.06), 0 1px 30px 0 rgba(85, 85, 85, 0.03) !important;
}
</style>
