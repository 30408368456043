var masterRoutes = [
  {
    path: "/master/sites",
    name: "Site",
    component: () => import("@/views/Master/SiteView"),
  },
  {
    path: "/master/workkinds",
    name: "WorkKind",
    component: () => import("@/views/Master/WorkKindView"),
  },
  {
    path: "/master/areas",
    name: "Area",
    component: () => import("@/views/Master/AreaView"),
  },
  {
    path: "/master/parts",
    name: "Part",
    component: () => import("@/views/Master/PartView"),
  },
  {
    path: "/master/partdetails",
    name: "PartDetail",
    component: () => import("@/views/Master/PartDetailView"),
  },
  {
    path: "/master/areapartmaps",
    name: "AreaPartMap",
    component: () => import("@/views/Master/AreaPartMapView"),
  },
  {
    path: "/master/takeoveritems",
    name: "TakeOverItem",
    component: () => import("@/views/Master/TakeOverItemView"),
  },
  {
    path: "/master/csnumber",
    name: "CsNumber",
    component: () => import("@/views/Master/CsNumberView"),
  },
  {
    path: "/master/takeoverdocs",
    name: "TakeOverDocsView",
    component: () => import("@/views/Master/TakeOverDocsView"),
  },
  {
    path: "/master/defectCause",
    name: "DefectCauseView",
    component: () => import("@/views/Master/DefectCauseView"),
  },
];

export { masterRoutes };
