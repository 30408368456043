import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";

import { publishRoutes } from "./publish.js";
import { masterRoutes } from "./master.js";
import { siteRoutes } from "./site.js";
import { systemRoutes } from "./system.js";
import { csRoutes } from "./cs.js";
import { defectRoutes } from "./defect.js";
import { disputeRoutes } from "./dispute.js";
import { analysisRoutes } from "./analysis.js";
import { partnersRoutes } from "./partners.js";
import { openRoutes } from "./open.js";
import { isMobileDevice } from "@/utils/common.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    name: "DashboardView",
    component: () => import("@/views/DashboardView"),
  },
  {
    path: "/mobile",
    name: "Mobile",
    meta: {
      noLayout: true,
    },
    component: () => import("@/views/Mobile"),
  },
  {
    path: "/mobile/logout",
    name: "MobileLogout",
    meta: {
      noLayout: true,
    },
    component: () => import("@/views/MobileLogout"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      noLayout: true,
    },
    component: () => import("@/views/User/LoginView"),
  },
  {
    path: "/callback",
    name: "Callback",
    meta: {
      noLayout: true,
      openURL: true,
    },
    component: () => import("@/views/User/CallbackView"),
  },
  {
    path: "/auth",
    name: "Auth",
    meta: {
      ssoLogin: true,
      noLayout: true,
      openURL: true,
    },
    component: () => import("@/views/User/SSO"),
  },
]
  .concat(publishRoutes)
  .concat(masterRoutes)
  .concat(siteRoutes)
  .concat(systemRoutes)
  .concat(csRoutes)
  .concat(defectRoutes)
  .concat(disputeRoutes)
  .concat(analysisRoutes)
  .concat(partnersRoutes)
  .concat(openRoutes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // layout(leftmenu,tab..) 사용 여부
  if (to.meta.noLayout) {
    store.commit("SET_ISLAYOUT", false);
  } else {
    store.commit("SET_ISLAYOUT", true);

    //이동중인 fullPath의 탭이 켜있지 않은 경우 openTab에 추가한다.
    var openTabs = store.getters.getOpenTabs;
    var openTabsPaths = openTabs.map((x) => x.fullPath);
    if (!openTabsPaths.includes(to.fullPath)) {
      var tab = {
        fullPath: to.fullPath,
        path: to.path,
      };

      if (!isMobileDevice()) {
        store.commit("SET_OPENTABS", tab);
      }
    }
  }

  next();
});
export default router;
