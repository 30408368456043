import store from "@/store/index";
import * as Cognito from "amazon-cognito-identity-js";
import * as User from "@/api/user";
import * as System from "@/api/system";
import router from "@/router";
import { isMobileDevice } from "@/utils/common.js";

/** Cognito Pool Setting */
const cognitoUserPool = new Cognito.CognitoUserPool({
  UserPoolId: process.env.VUE_APP_USER_POOL_ID,
  ClientId: process.env.VUE_APP_CLIENT_ID,
});

/** Cognito Login */
export async function logIn(payload) {
  store.commit("CLEAR");

  const authDetails = new Cognito.AuthenticationDetails({
    Username: payload.username,
    Password: payload.password,
  });

  const cognitoUser = new Cognito.CognitoUser({
    Pool: cognitoUserPool,
    Username: payload.username,
  });

  let deviceToken = "";

  try {
    if (!isMobileDevice()) {
      deviceToken = await User.getDeviceToken();
    }
  } catch {
    console.log("deviceTokenNotFound");
  }

  return new Promise((resolve, reject) =>
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: function (res) {
        payload.idToken = res.idToken.jwtToken;
        payload.refreshToken = res.refreshToken.token;
        payload.exp = res.idToken.payload.exp;
        payload.isSSOUser = false;
        store.commit("SET_TOKEN", payload);

        let info = {};
        if (deviceToken) {
          info.fcmToken = deviceToken;
        }
        User.serverLogin(info)
          .then(() => {
            // login 로그 저장
            System.postActivity({
              url: "/login",
              method: "POST",
            });
            resolve("SUCCESS");
          })
          .catch((err) => {
            reject(err);
          });
      },
      onFailure: (err) => {
        store.commit("CLEAR");
        reject(err);
      },
    })
  );
}

/** Cognito Logout */
export function logOut(path = "Login") {
  // logout 로그 저장
  System.postActivity({
    url: "/logout",
    method: "POST",
  });

  return new Promise((resolve) => {
    if (store.getters.getUser.username) {
      const cognitoUser = new Cognito.CognitoUser({
        Pool: cognitoUserPool,
        Username: store.getters.getUser.username,
      });
      cognitoUser.signOut();
    }

    store.commit("CLEAR");
    router.push({ name: path });
    resolve(true);
  });
}
export function mobileLogOut() {
  // logout 로그 저장
  System.postActivity({
    url: "/logout",
    method: "POST",
  });

  return new Promise((resolve) => {
    if (store.getters.getUser.username) {
      const cognitoUser = new Cognito.CognitoUser({
        Pool: cognitoUserPool,
        Username: store.getters.getUser.username,
      });
      cognitoUser.signOut();
    }

    store.commit("CLEAR");
    resolve(true);
  });
}
/** Cognito SignUp */
export function signUp(payload) {
  const userAttributes = ["name", "email", "phone_number"].map(
    (key) =>
      new Cognito.CognitoUserAttribute({
        Name: key,
        Value: payload[key],
      })
  );

  return new Promise((resolve, reject) => {
    cognitoUserPool.signUp(
      payload.username,
      payload.password,
      userAttributes,
      null,
      (err, data) => {
        if (!err) {
          resolve(data);
          return;
        }
        reject(err);
      }
    );
  });
}

/** Cognito SignUp Confirm */
export function confirmSignUp(payload) {
  const cognitoUser = new Cognito.CognitoUser({
    Pool: cognitoUserPool,
    Username: payload.username,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(payload.confirmcode, false, (err) => {
      if (!err) {
        resolve();
        return;
      }
      reject(err);
    });
  });
}

export function refreshToken() {
  return new Promise((resolve, reject) => {
    const cognitoUser = new Cognito.CognitoUser({
      Pool: cognitoUserPool,
      Username: store.getters.getUser.username,
    });

    cognitoUser.getSession(function (err, session) {
      console.log(session);
      if (!session) {
        logOut();
        reject("no session");
      } else {
        var refresh_token = session.getRefreshToken();
        cognitoUser.refreshSession(refresh_token, (err, res) => {
          if (err) {
            reject();
          } else {
            let payload = {};
            payload.idToken = res.idToken.jwtToken;
            payload.refreshToken = res.refreshToken.token;
            store.commit("SET_TOKEN", payload);
            User.getUserInfo()
              .then((user) => {
                store.commit("SET_USER", user.data);
                resolve(res);
              })
              .catch((err) => {
                store.commit("CLEAR");
                reject(err);
              });
            resolve(res);
          }
        });
      }
    });
  });
}

export function resendConfirmationCode(username) {
  const cognitoUser = new Cognito.CognitoUser({
    Pool: cognitoUserPool,
    Username: username,
  });
  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode((err, res) => {
      if (!err) {
        resolve(res);
        return;
      }
      reject(err);
    });
  });
}

export function confirmPassword(payload) {
  const cognitoUser = new Cognito.CognitoUser({
    Pool: cognitoUserPool,
    Username: payload.username,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(payload.confirmcode, payload.newPassword, {
      onFailure(err) {
        reject(err);
      },
      onSuccess(res) {
        resolve(res);
      },
    });
  });
}

export function forgotPassword(payload) {
  const cognitoUser = new Cognito.CognitoUser({
    Pool: cognitoUserPool,
    Username: payload.username,
  });

  return new Promise((resolve, reject) =>
    cognitoUser.forgotPassword({
      onSuccess(res) {
        resolve(res);
      },
      onFailure(err) {
        reject(err);
      },
    })
  );
}
