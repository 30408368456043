var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',{class:{
    'common-button': true,
    'export-button': !_vm.small && !_vm.functional,
    'small-button': _vm.small,
    'function-button': _vm.functional,
    disabled: _vm.disabled || _vm.checkDisabled,
  },attrs:{"outlined":"","elevation":"0","disabled":_vm.disabled || _vm.checkDisabled},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(" "+_vm._s(_vm.text)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }