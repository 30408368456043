<template>
  <div data-app>
    <v-snackbar
      :timeout="3000"
      v-model="snackbar.show"
      top
      right
      :globalTop="true"
      outlined
      :color="color"
      elevation="24"
      class="snackbar"
      :height="25"
    >
      <!-- transition="slide-x-reverse-transition" -->
      <v-icon :color="color">{{ icon }}</v-icon>
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" icon @click="snackbar_close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    snackbar() {
      return this.$store.getters.getSnackBar;
    },
    show() {
      return this.$store.getters.getSnackBar.show;
    },
    text() {
      return this.snackbar ? this.snackbar.text : "";
    },
    icon() {
      // -outline
      return {
        INFO: "mdi-information",
        SUCCESS: "mdi-check-circle",
        WARN: "mdi-alert-circle",
      }[this.snackbar.type];
    },
    color() {
      return {
        INFO: "#2196F3",
        SUCCESS: "#4CAF50",
        WARN: "#FF5252",
      }[this.snackbar.type];
    },
  },
  watch: {
    show(val) {
      if (!val) {
        this.$store.commit("SET_EMPTY");
      }
    },
  },
  methods: {
    snackbar_close() {
      this.$store.commit("SET_EMPTY");
    },
  },
  created() {
    this.$store.commit("SET_EMPTY");
  },
};
</script>
