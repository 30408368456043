var publishRoutes = [
  //템플릿 라우터 임포트
  {
    path: "/template/plogin",
    name: "PublicLogin",
    component: () => import("@/template/login/login"),
  },
  {
    path: "/template/dashboard/dashboard",
    name: "PublicDashBoard",
    component: () => import("@/template/dashboard/publicdashboard"),
  },
  {
    path: "/template/complaint/view",
    name: "Complaintreception",
    component: () => import("@/template/complaint/view"),
  },
  {
    path: "/template/complaint/title",
    name: "Comtitle",
    component: () => import("@/template/complaint/title"),
  },
  {
    path: "/template/review/view",
    name: "Review",
    component: () => import("@/template/review/view"),
  },
  {
    path: "/template/dashboard/view",
    name: "PublicDashBoard2",
    component: () => import("@/template/dashboard/view"),
  },
  {
    path: "/template/survey/survey",
    name: "Survey",
    meta: {
      noLayout: true,
    },
    component: () => import("@/template/survey/survey"),
  },
  {
    path: "/template/partners/view",
    name: "partners",
    meta: {
      noLayout: true,
    },
    component: () => import("@/template/partners/view"),
  },
  {
    path: "/template/partners/inview",
    name: "partnersIn",
    meta: {
      noLayout: true,
    },
    component: () => import("@/template/partners/inview"),
  },
  {
    path: "/template/hosdesign/view",
    name: "HosDesign",
    meta: {
      noLayout: true,
    },
    component: () => import("@/template/hosdesign/view"),
  },
  {
    path: "/template/defectmobile/list",
    name: "DefectMobileList",
    meta: {
      noLayout: true,
    },
    component: () => import("@/template/defectmobile/list"),
  },
  {
    path: "/template/defectmobile/view",
    name: "DefectMobileView",
    meta: {
      noLayout: true,
    },
    component: () => import("@/template/defectmobile/view"),
  },
];

export { publishRoutes };
