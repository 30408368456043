<template>
  <v-tabs show-arrows v-model="selectedTab" active-class="active">
    <v-tab
      v-for="tab in openTabs"
      :key="tab.fullPath"
      :value="tab.fullPath"
      @click="goPage(tab.fullPath)"
      @mouseover="hoverPath = tab.fullPath"
      @mouseleave="hoverPath = null"
    >
      <v-badge
        icon="mdi-close"
        v-if="hoverPath == tab.fullPath && openTabs.length > 1"
        @click.native="close(tab.fullPath)"
      >
        <span @click.stop="goPage(tab.fullPath)">
          {{ getTitle(tab.path) }}
        </span>
      </v-badge>
      <span v-else>
        {{ getTitle(tab.path) }}
      </span>
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "Tabs",
  data() {
    return {
      selectedTab: null,
      hoverPath: false,
    };
  },
  computed: {
    ...mapGetters({
      openTabs: "getOpenTabs",
      pages: "getPages",
    }),
    currentPath() {
      return this.$route.fullPath;
    },
  },
  watch: {
    $route(to) {
      this.focusTab(to.fullPath);
    },
  },
  methods: {
    ...mapActions(["allTabClose"]),
    ...mapMutations({
      delOpenTabs: "DEL_OPENTABS",
    }),

    goPage(path) {
      if (this.currentPath != path) {
        this.$router.push({ path: path });
      }
    },
    close(path) {
      var idx = this.openTabs.findIndex((item) => item.fullPath == path);
      this.delOpenTabs(path);

      if (this.openTabs.length == 0) {
        this.$router.push("/");
      } else if (this.$route.fullPath == path) {
        if (idx == 0) {
          this.$router.push(this.openTabs[0].fullPath);
        } else {
          this.$router.push(this.openTabs[idx - 1].fullPath);
        }
      }

      this.focusTab(path);
    },
    focusTab() {
      this.openTabs.forEach((tab, i) => {
        if (tab.fullPath == this.$route.fullPath) {
          this.selectedTab = i;
        }
      });
    },
    allClose() {
      this.allTabClose(this.$route);
    },
    getTitle(path) {
      path = path.replace(/[0-9]/g, "");
      if (path[path.length - 1] == "/" && path != "/") {
        path = path.slice(0, path.length - 1);
      }

      var menu = this.pages.find((item) => item.url == path);

      if (!menu) {
        menu = this.pages.find((item) => {
          return item.url && path.includes(item.url);
        });
      }

      if (menu) {
        return menu.title;
      } else {
        return path;
      }
    },
  },
};
</script>

<style>
#DSMainBox
  > header
  > div
  > div.v-tabs.theme--light
  > div
  > div.v-slide-group__wrapper {
  height: 48px !important;
}
.active {
  background-color: rgba(0, 68, 131, 0.1);
}
.v-tab {
  letter-spacing: 0;
  padding: 0 25px;
}
.v-application .v-badge__wrapper {
  top: 3px;
  right: 0 !important;
}
.v-application .v-badge__badge {
  inset: auto auto calc(100% - 4px) 100% !important;
  background-color: sienna !important;
}
</style>
