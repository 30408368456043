<template>
  <div class="ModalPopBox">
    <div class="ModalInBox full">
      <div class="HeaderBox">
        <strong class="MdTitle">전화수신 ({{ callData.callTo }})</strong>
        <span class="MdCloseBtn" @click="close()"></span>
      </div>
      <div class="ModalCont">
        <div class="thirdTable">
          <div class="tTItem">
            <div class="tTinItem">
              <strong>현장</strong>
              <p>{{ callData.siteName }} {{ callData.dongHo }}</p>
            </div>
            <div class="tTinItem">
              <strong>고객 정보</strong>
              <p>
                {{ callData.customerName }} ({{ callData.callFrom }})
                <v-btn
                  elevation="0"
                  outlined
                  fab
                  x-small
                  color="#f41e20"
                  style="margin-left: 5px; font-weight: bold"
                  v-show="callData.customerTemperature == 'HOT'"
                >
                  <v-icon>mdi-fire</v-icon>
                </v-btn>
                <v-btn
                  elevation="0"
                  outlined
                  rounded
                  small
                  color="#2e7bff"
                  style="margin-left: 5px; font-weight: bold"
                  v-show="JSON.parse(callData.isContractor || 'false')"
                  >계약자</v-btn
                >
                <v-btn
                  elevation="0"
                  outlined
                  rounded
                  small
                  color="#20bd6d"
                  style="margin-left: 5px; font-weight: bold"
                  v-show="JSON.parse(callData.isTenant || 'false')"
                  >입주자</v-btn
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="btnPopBox">
        <button
          style="width: 300px"
          class="style3"
          @click="goTicket(callData.id)"
        >
          민원접수
        </button>
        <button class="style4" @click="close()">닫기</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CallPopup",
  props: ["callData"],
  data() {
    return {};
  },
  components: {},
  methods: {
    close() {
      this.$emit("close");
    },
    goTicket(id) {
      this.$router.push({
        path: `/cs/ticket`,
        query: { cs_id: id },
      });
      this.$emit("close");
    },
  },
};
</script>
