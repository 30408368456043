<template>
  <div class="ReBtn">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        text
          class="refresh-button"
          @click="click()"
          v-bind="attrs"
          v-on="on"

        >
          <v-icon id="refresh-icon" class="refresh-icon toggleUpDown" 
            >mdi-cached</v-icon
          >
        </v-btn>
      </template>
      <span>새로고침</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "Refresh",
  props: {
    isLoading: Boolean,
  },
  data() {
    return {
      deg: 0,
    };
  },
  methods: {
    click() {
      this.spin();
      this.$emit("click");
    },
    spin() {
      let el = document.getElementById("refresh-icon");
      this.deg += 180;
      el.style.transform = `rotate(${this.deg}deg)`;
    },
  },
};
</script>

<style scoped>
.refresh-button {
  /*  margin-bottom: -5px;*/
  /*  margin-left: 2.5px;*/
  width:60px;
  height:60px;
}
.refresh-icon {
  color: #004483 !important;
}

.toggleUpDown {
  transition: transform 0.3s ease-in-out !important;
}
</style>
